@charset "UTF-8";
@font-face {
  font-family: Loos ExtraWide;
  font-display: swap;
  src: url("/fonts/Loos ExtraWide-Light-Web.woff2") format("woff2"), url("/fonts/Loos ExtraWide-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Loos ExtraWide;
  font-display: swap;
  src: url("/fonts/Loos ExtraWide-Medium-Web.woff2") format("woff2"), url("/fonts/Loos ExtraWide-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Loos Normal;
  font-display: swap;
  src: url("/fonts/Loos Normal-Medium-Web.woff2") format("woff2"), url("/fonts/Loos Normal-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Loos ExtraWide;
  font-display: swap;
  src: url("/fonts/Loos ExtraWide-Regular-Web.woff2") format("woff2"), url("/fonts/Loos ExtraWide-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Maregraphe Display;
  font-display: swap;
  src: url("/fonts/Maregraphe Display-Light-Web.woff2") format("woff2"), url("/fonts/Maregraphe Display-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Loos Normal;
  font-display: swap;
  src: url("/fonts/Loos Normal-Regular-Web.woff2") format("woff2"), url("/fonts/Loos Normal-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  min-width: 320px;
}
body {
  color: #202020;
  line-height: 1;
  font-family: "Loos ExtraWide";
  font-size: 0.875rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input,
button,
textarea {
  font-family: "Loos ExtraWide";
  font-size: inherit;
}
button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}
a {
  color: inherit;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}
body {
  background-color: #fbf0e9;
}
.lock body {
  overflow: hidden;
  touch-action: none;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}
.wrapper._article {
  overflow: unset;
}
.wrapper > main {
  flex: 1 1 auto;
}
.wrapper > * {
  min-width: 0;
}
/*
(i) Стили будут применяться ко 
всем классам содержащим *__container
Например header__container, main__container и т.д.
Снипет (HTML): cnt
*/
[class*=__container] {
  box-sizing: content-box;
  max-width: 89.25rem;
  margin: 0 auto;
  padding: 0 0.75rem;
}
body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 149;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
}
body.popup-show::after {
  opacity: 1;
}
.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px 10px;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}
.popup__close {
  position: absolute;
  top: 0;
  top: -2px;
  right: 14px;
  z-index: 2;
  margin-left: auto;
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
  font-family: Loos ExtraWide;
  text-transform: lowercase;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .popup__close {
      font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .popup__close {
      font-size: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .popup__close {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .popup__close {
      line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .popup__close {
      line-height: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .popup__close {
    line-height: 2rem;
  }
}
@media (max-width: 1024px) {
  .popup__close {
    top: 0;
  }
}
@media (max-width: 425px) {
  .popup__close {
    top: 5px;
    right: 12px;
  }
}
.popup_show {
  z-index: 150;
  overflow: auto;
  visibility: visible;
  pointer-events: auto;
}
.popup_show .popup__content {
  transform: scale(1);
  visibility: visible;
}
.popup__wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
}
.popup__content {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fbf0e9;
  transform: scale(0);
  visibility: hidden;
}
.lock .popup__content {
  visibility: visible;
}
@media (max-width: 768px) {
  .popup--list .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.popup--list .menu__wrap {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .popup--list .menu__wrap {
    padding-bottom: 60px;
  }
}
.popup--list .menu__body {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  min-height: unset;
}
@media (min-width: 90em) {
  .popup--list .menu__body {
    padding-top: 7.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-top: clamp( 5.3125rem , 4.6875rem  +  3.125vw , 7.5rem )) {
    .popup--list .menu__body {
      padding-top: clamp( 5.3125rem , 4.6875rem  +  3.125vw , 7.5rem );
    }
  }
  @supports not (padding-top: clamp( 5.3125rem , 4.6875rem  +  3.125vw , 7.5rem )) {
    .popup--list .menu__body {
      padding-top: calc(5.3125rem + 2.1875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .popup--list .menu__body {
    padding-top: 5.3125rem;
  }
}
.popup--list .menu__search {
  padding-right: 12px;
}
@media (min-width: 90em) {
  .popup--list .menu__search {
    margin-left: 19.6875rem;
  }
}
@media (min-width: 48.0625em) and (max-width: 90em) {
  @supports (margin-left: clamp( 1.25rem , -19.8803092399rem  +  43.9642324888vw , 19.6875rem )) {
    .popup--list .menu__search {
      margin-left: clamp( 1.25rem , -19.8803092399rem  +  43.9642324888vw , 19.6875rem );
    }
  }
  @supports not (margin-left: clamp( 1.25rem , -19.8803092399rem  +  43.9642324888vw , 19.6875rem )) {
    .popup--list .menu__search {
      margin-left: calc(1.25rem + 18.4375 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (max-width: 48.0625em) {
  .popup--list .menu__search {
    margin-left: 1.25rem;
  }
}
@media (max-width: 768px) {
  .popup--list .menu__search {
    order: 1;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-top: auto;
    padding-right: 0;
  }
}
.popup--list .menu__list {
  width: 100%;
  margin-bottom: 72px;
}
@media (max-width: 768px) {
  .popup--list .menu__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: -2;
    margin-bottom: 0;
    margin-bottom: 72px;
  }
}
.popup--list .menu__item {
  display: flex;
  justify-content: center;
  width: 100%;
}
.popup--list .menu__link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 197px;
  padding-left: 315px;
  font-weight: 300;
  font-size: 96px;
  line-height: 96px;
  color: #202020;
  letter-spacing: -0.05em;
}
@media (min-width: 48.0625em) and (max-width: 90em) {
  @supports (padding-left: clamp( 1.25rem , -19.8803092399rem  +  43.9642324888vw , 19.6875rem )) {
    .popup--list .menu__link {
      padding-left: clamp( 1.25rem , -19.8803092399rem  +  43.9642324888vw , 19.6875rem );
    }
  }
  @supports not (padding-left: clamp( 1.25rem , -19.8803092399rem  +  43.9642324888vw , 19.6875rem )) {
    .popup--list .menu__link {
      padding-left: calc(1.25rem + 18.4375 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (min-height: 1201px) and (min-width: 48.0625em) and (max-width: 90em) {
  @supports (height: clamp( 4.5rem , -4.4535208644rem  +  18.6289120715vw , 12.3125rem )) {
    .popup--list .menu__link {
      height: clamp( 4.5rem , -4.4535208644rem  +  18.6289120715vw , 12.3125rem );
    }
  }
  @supports not (height: clamp( 4.5rem , -4.4535208644rem  +  18.6289120715vw , 12.3125rem )) {
    .popup--list .menu__link {
      height: calc(4.5rem + 7.8125 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (min-height: 1201px) and (min-width: 48.0625em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , -2.5842026826rem  +  9.5380029806vw , 6rem )) {
    .popup--list .menu__link {
      font-size: clamp( 2rem , -2.5842026826rem  +  9.5380029806vw , 6rem );
    }
  }
  @supports not (font-size: clamp( 2rem , -2.5842026826rem  +  9.5380029806vw , 6rem )) {
    .popup--list .menu__link {
      font-size: calc(2rem + 4 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (min-height: 1201px) and (min-width: 48.0625em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , -2.5842026826rem  +  9.5380029806vw , 6rem )) {
    .popup--list .menu__link {
      line-height: clamp( 2rem , -2.5842026826rem  +  9.5380029806vw , 6rem );
    }
  }
  @supports not (line-height: clamp( 2rem , -2.5842026826rem  +  9.5380029806vw , 6rem )) {
    .popup--list .menu__link {
      line-height: calc(2rem + 4 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (max-width: 768px) {
  .popup--list .menu__link {
    display: flex;
    justify-content: center;
    height: 72px;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    padding-left: 0;
  }
}
@media (max-height: 1200px) and (min-width: 1441px) {
  .popup--list .menu__link {
    height: 120px;
    font-size: 56px;
    line-height: 56px;
  }
}
@media (max-height: 1200px) and (min-width: 48.0625em) and (max-width: 90em) {
  @supports (height: clamp( 4.5rem , 1.0618479881rem  +  7.1535022355vw , 7.5rem )) {
    .popup--list .menu__link {
      height: clamp( 4.5rem , 1.0618479881rem  +  7.1535022355vw , 7.5rem );
    }
  }
  @supports not (height: clamp( 4.5rem , 1.0618479881rem  +  7.1535022355vw , 7.5rem )) {
    .popup--list .menu__link {
      height: calc(4.5rem + 3 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (max-height: 1200px) and (min-width: 48.0625em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 0.280923994rem  +  3.5767511177vw , 3.5rem )) {
    .popup--list .menu__link {
      font-size: clamp( 2rem , 0.280923994rem  +  3.5767511177vw , 3.5rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 0.280923994rem  +  3.5767511177vw , 3.5rem )) {
    .popup--list .menu__link {
      font-size: calc(2rem + 1.5 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (max-height: 1200px) and (min-width: 48.0625em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 0.280923994rem  +  3.5767511177vw , 3.5rem )) {
    .popup--list .menu__link {
      line-height: clamp( 2rem , 0.280923994rem  +  3.5767511177vw , 3.5rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 0.280923994rem  +  3.5767511177vw , 3.5rem )) {
    .popup--list .menu__link {
      line-height: calc(2rem + 1.5 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
.popup--list .menu__link:hover {
  background-color: #e7fe59;
}
.popup--list .menu__footer {
  margin-top: auto;
  position: absolute;
  bottom: 12px;
  right: 12px;
}
@media (max-width: 768px) {
  .popup--list .menu__footer {
    margin-top: 40px;
    margin-bottom: 0;
    position: absolute;
    top: unset;
    left: unset;
    right: unset;
    width: 100%;
    bottom: 0;
    background-color: #fbf0e9;
    padding: 12px 0;
  }
}
.popup--list .menu__social {
  margin-left: auto;
  margin-right: 12px;
}
@media (max-width: 768px) {
  .popup--list .menu__social {
    margin-left: unset;
    margin-right: 0;
  }
}
.popup--list .menu-search {
  max-width: 822px;
  margin-bottom: 32px;
}
@media (min-width: 48.0625em) and (max-width: 90em) {
  @supports (max-width: clamp( 44.375rem , 36.3526453055rem  +  16.6915052161vw , 51.375rem )) {
    .popup--list .menu-search {
      max-width: clamp( 44.375rem , 36.3526453055rem  +  16.6915052161vw , 51.375rem );
    }
  }
  @supports not (max-width: clamp( 44.375rem , 36.3526453055rem  +  16.6915052161vw , 51.375rem )) {
    .popup--list .menu-search {
      max-width: calc(44.375rem + 7 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (max-width: 768px) {
  .popup--list .menu-search {
    max-width: 60%;
    width: 60%;
    height: auto;
  }
}
@media (max-width: 425px) {
  .popup--list .menu-search {
    width: 92%;
    max-width: 92%;
  }
}
@media (max-width: 768px) {
  .popup--list .menu-search__input {
    padding: 15px 24px 10px 24px;
  }
}
[class*=-ibg] {
  position: relative;
}
[class*=-ibg] img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
[class*=-ibg_contain] img {
  object-fit: contain;
}
#mount {
  height: 100%;
}
._purple {
  background: #f4d4fc;
}
.filters {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
  gap: 12px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (column-gap: clamp( 0.25rem , 0.1071428571rem  +  0.7142857143vw , 0.75rem )) {
    .filters {
      column-gap: clamp( 0.25rem , 0.1071428571rem  +  0.7142857143vw , 0.75rem );
    }
  }
  @supports not (column-gap: clamp( 0.25rem , 0.1071428571rem  +  0.7142857143vw , 0.75rem )) {
    .filters {
      column-gap: calc(0.25rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .filters {
    column-gap: 0.25rem;
  }
}
@media (min-width: 20em) and (max-width: 48em) {
  @supports (width: clamp( 20rem , 2.2321428571rem  +  88.8392857143vw , 44.875rem )) {
    .filters {
      width: clamp( 20rem , 2.2321428571rem  +  88.8392857143vw , 44.875rem );
    }
  }
  @supports not (width: clamp( 20rem , 2.2321428571rem  +  88.8392857143vw , 44.875rem )) {
    .filters {
      width: calc(20rem + 24.875 * (100vw - 20rem) / 28);
    }
  }
}
@media (max-width: 20em) {
  .filters {
    width: 20rem;
  }
}
@media (max-width: 768px) {
  .filters {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.filters__item {
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 27px 46px 25px 48px;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  border: 1px solid #202020;
  border-radius: 36px;
  transition: 0.3s;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .filters__item {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .filters__item {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .filters__item {
    font-size: 0.875rem;
  }
}
.filters__item:first-child {
  margin-left: 12px;
}
.filters__item:last-child {
  margin-right: 12px;
}
@media (min-width: 768px) {
  .filters__item:nth-child(3n+1) {
    margin-left: 12px;
  }
}
@media (max-width: 1279px) {
  .filters__item {
    padding: 18px 36px 18px 36px;
  }
}
.filters__item:hover {
  color: #ffffff;
  background-color: #202020;
}
.filters__item._active {
  color: #ffffff;
  background: #202020;
}
.infinite-line {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  display: flex;
  width: 100%;
  height: 197px;
  margin-top: 106px;
  background-color: #322740;
  transform: rotate(3deg);
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-top: clamp( 4.75rem , 4.2142857143rem  +  2.6785714286vw , 6.625rem )) {
    .infinite-line {
      margin-top: clamp( 4.75rem , 4.2142857143rem  +  2.6785714286vw , 6.625rem );
    }
  }
  @supports not (margin-top: clamp( 4.75rem , 4.2142857143rem  +  2.6785714286vw , 6.625rem )) {
    .infinite-line {
      margin-top: calc(4.75rem + 1.875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .infinite-line {
    margin-top: 4.75rem;
  }
}
@media (min-width: 48em) and (max-width: 64em) {
  @supports (height: clamp( 7.5rem , -6.9375rem  +  30.078125vw , 12.3125rem )) {
    .infinite-line {
      height: clamp( 7.5rem , -6.9375rem  +  30.078125vw , 12.3125rem );
    }
  }
  @supports not (height: clamp( 7.5rem , -6.9375rem  +  30.078125vw , 12.3125rem )) {
    .infinite-line {
      height: calc(7.5rem + 4.8125 * (100vw - 48rem) / 16);
    }
  }
}
@media (min-width: 20em) and (max-width: 48em) {
  @supports (height: clamp( 4.5rem , 4.1428571429rem  +  1.7857142857vw , 5rem )) {
    .infinite-line {
      height: clamp( 4.5rem , 4.1428571429rem  +  1.7857142857vw , 5rem );
    }
  }
  @supports not (height: clamp( 4.5rem , 4.1428571429rem  +  1.7857142857vw , 5rem )) {
    .infinite-line {
      height: calc(4.5rem + 0.5 * (100vw - 20rem) / 28);
    }
  }
}
@media (max-width: 20em) {
  .infinite-line {
    height: 4.5rem;
  }
}
.infinite-line:before {
  content: "";
  position: absolute;
  top: 0;
  left: -12px;
  z-index: -1;
  width: 14px;
  height: 94%;
  background-color: #322740;
  transform: rotate(3deg);
}
.infinite-line:after {
  content: "";
  position: absolute;
  right: -12px;
  bottom: 0;
  z-index: -1;
  width: 14px;
  height: 94%;
  background-color: #322740;
  transform: rotate(3deg);
}
.infinite-line._2 {
  z-index: -1;
  background-color: #e7fe59;
}
@media (max-width: 768px) {
  .infinite-line._2 {
    margin-top: 43px;
  }
}
.infinite-line._2:before {
  background-color: #e7fe59;
}
.infinite-line._2:after {
  background-color: #e7fe59;
}
.infinite-line__block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Loos ExtraWide;
  color: #f4d4fc;
  /* animation: animate 7s linear infinite; */
}
.infinite-line__block._2 {
  color: #202020;
  /* animation: animate_2 4s linear infinite; */
}
.infinite-line__block p {
  width: 100%;
  margin-left: 170px;
  font-weight: 300;
  font-size: 96px;
  letter-spacing: -0.05em;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .infinite-line__block p {
    font-size: 32px;
  }
}
/* @media (max-width: 768px) {
  @keyframes animate {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-510px);
    }
  }
} */
/* @media (min-width: 769px) {
  @keyframes animate {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-972px);
    }
  }
} */
/* @media (max-width: 768px) {
  @keyframes animate_2 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-366px);
    }
  }
}
@media (min-width: 769px) {
  @keyframes animate_2 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-755px);
    }
  }
} */
.menu {
  width: 100%;
}
.menu__body {
  min-height: 400px;
  padding-top: 38px;
}
.menu__body .infinite-line {
  z-index: 2;
  margin-top: 28px;
  transform: rotate(5deg);
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-top: clamp( 0.00000625rem , -0.4999919643rem  +  2.4999910714vw , 1.75rem )) {
    .menu__body .infinite-line {
      margin-top: clamp( 0.00000625rem , -0.4999919643rem  +  2.4999910714vw , 1.75rem );
    }
  }
  @supports not (margin-top: clamp( 0.00000625rem , -0.4999919643rem  +  2.4999910714vw , 1.75rem )) {
    .menu__body .infinite-line {
      margin-top: calc(0.00000625rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .menu__body .infinite-line {
    margin-top: 0.00000625rem;
  }
}
@media (min-width: 769px) and (max-height: 768px) {
  .menu__body .infinite-line {
    margin-top: -35px !important;
  }
}
.menu__body .infinite-line:before {
  content: "";
  position: absolute;
  top: 0;
  left: -35px;
  z-index: -1;
  width: 38px;
  height: 100%;
  transform: rotate(0deg);
}
.menu__body .infinite-line:after {
  content: "";
  position: absolute;
  right: -30px;
  bottom: 0;
  z-index: -1;
  width: 35px;
  height: 100%;
  transform: rotate(359deg);
}
.menu__body .infinite-line__block {
  animation: animate2 3s linear infinite;
}
.menu__body .infinite-line__block p {
  margin-left: 135px;
}
.menu__body .infinite-line._1 {
  z-index: 1;
  margin-top: 106px;
  color: mainColor;
  background: #f4d4fc;
  transform: rotate(-3deg);
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports ( margin-top: clamp( 4.125rem , 3.4107142857rem  +  3.5714285714vw , 6.625rem )) {
    .menu__body .infinite-line._1 {
       margin-top: clamp( 4.125rem , 3.4107142857rem  +  3.5714285714vw , 6.625rem );
    }
  }
  @supports not ( margin-top: clamp( 4.125rem , 3.4107142857rem  +  3.5714285714vw , 6.625rem )) {
    .menu__body .infinite-line._1 {
       margin-top: calc(4.125rem + 2.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .menu__body .infinite-line._1 {
     margin-top: 4.125rem;
  }
}
@media (min-width: 769px) and (max-height: 768px) {
  .menu__body .infinite-line._1 {
    margin-top: 0 !important;
  }
}
.menu__body .infinite-line._1:before {
  background-color: #f4d4fc;
}
.menu__body .infinite-line._1:after {
  right: -23px;
  bottom: 0;
  z-index: -1;
  width: 27px;
  background-color: #f4d4fc;
}
.menu__body .infinite-line._1 .infinite-line__block._1 {
  animation: animate3 3s linear infinite !important;
}
.menu__body .infinite-line._1 p {
  color: #202020;
}
.menu__body .infinite-line._2 {
  z-index: 3;
  margin-top: 20px;
  transform: rotate(-10deg);
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-top: clamp( 0.00000625rem , -0.3571348214rem  +  1.7857053571vw , 1.25rem )) {
    .menu__body .infinite-line._2 {
      margin-top: clamp( 0.00000625rem , -0.3571348214rem  +  1.7857053571vw , 1.25rem );
    }
  }
  @supports not (margin-top: clamp( 0.00000625rem , -0.3571348214rem  +  1.7857053571vw , 1.25rem )) {
    .menu__body .infinite-line._2 {
      margin-top: calc(0.00000625rem + 1.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .menu__body .infinite-line._2 {
    margin-top: 0.00000625rem;
  }
}
@media (min-width: 769px) and (max-height: 768px) {
  .menu__body .infinite-line._2 {
    margin-top: -65px !important;
  }
}
.menu__body .infinite-line._2:after {
  right: -44px;
  bottom: 0.5px;
  z-index: -1;
  width: 52px;
  height: 100%;
  transform: rotate(-1deg);
}
.menu__body .infinite-line._2:before {
  content: "";
  position: absolute;
  top: 1px;
  left: -50px;
  z-index: -1;
  width: 80px;
  height: 98%;
  transform: rotate(3deg);
}
.menu__body .infinite-line._2 .infinite-line__block._2 {
  animation: animate4 4s linear infinite;
}
.menu__footer {
  position: absolute;
  right: 12px;
  bottom: 12px;
  left: 12px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 128px;
  margin-bottom: 12px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-top: clamp( 4.8125rem , 3.9017857143rem  +  4.5535714286vw , 8rem )) {
    .menu__footer {
      margin-top: clamp( 4.8125rem , 3.9017857143rem  +  4.5535714286vw , 8rem );
    }
  }
  @supports not (margin-top: clamp( 4.8125rem , 3.9017857143rem  +  4.5535714286vw , 8rem )) {
    .menu__footer {
      margin-top: calc(4.8125rem + 3.1875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .menu__footer {
    margin-top: 4.8125rem;
  }
}
@media (max-width: 1024px) {
  .menu__footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.menu__social {
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  @keyframes animate2 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-436px);
    }
  }
  @keyframes animate3 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-477px);
    }
  }
  @keyframes animate4 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-518px);
    }
  }
}
@media (min-width: 769px) {
  @keyframes animate2 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-386px);
    }
  }
  @keyframes animate3 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-446px);
    }
  }
  @keyframes animate4 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-505px);
    }
  }
}
.menu-search {
  position: relative;
  width: 100%;
  max-width: 860px;
  height: 72px;
}
@media (max-width: 1024px) {
  .menu-search {
    order: -1;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .menu-search {
    order: -1;
    margin-bottom: 102px;
  }
}
.menu-search__input {
  width: 100%;
  padding: 24px 168px 24px 48px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  background: #ffffff;
  border-radius: 36px;
}
@media (max-width: 768px) {
  .menu-search__input {
    height: 56px;
  }
}
@media (max-width: 423px) {
  .menu-search__input {
    padding: 18px 36px;
    font-size: 14px;
  }
}
.menu-search__btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 26px 45px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  color: #ffffff;
  background: #202020;
  border-radius: 36px;
  transition: all 0.3s linear;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-top: clamp( 1.25rem , 1.1428571429rem  +  0.5357142857vw , 1.625rem )) {
    .menu-search__btn {
      padding-top: clamp( 1.25rem , 1.1428571429rem  +  0.5357142857vw , 1.625rem );
    }
  }
  @supports not (padding-top: clamp( 1.25rem , 1.1428571429rem  +  0.5357142857vw , 1.625rem )) {
    .menu-search__btn {
      padding-top: calc(1.25rem + 0.375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .menu-search__btn {
    padding-top: 1.25rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 1.25rem , 1.1428571429rem  +  0.5357142857vw , 1.625rem )) {
    .menu-search__btn {
      padding-bottom: clamp( 1.25rem , 1.1428571429rem  +  0.5357142857vw , 1.625rem );
    }
  }
  @supports not (padding-bottom: clamp( 1.25rem , 1.1428571429rem  +  0.5357142857vw , 1.625rem )) {
    .menu-search__btn {
      padding-bottom: calc(1.25rem + 0.375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .menu-search__btn {
    padding-bottom: 1.25rem;
  }
}
.menu-search__btn:hover {
  background: #e35e59;
}
@media (max-width: 768px) {
  .menu-search__btn {
    position: static;
    width: 100%;
    height: 56px;
    margin-top: 6px;
    font-size: 14px;
  }
}
:not(#stk) .stk-post.stk-post {
  padding: 0 !important;
}
@media (max-width: 768px) {
  :not(#stk) .stk-post.stk-post .stk-grid {
    grid-gap: 0 !important;
  }
}
@media (max-width: 768px) {
  :not(#stk):not(.stk) .stk-post.stk-theme_45267.stk-post .stk-theme_45267__mb_05 {
    margin-top: 6px;
    margin-bottom: 0;
  }
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
}
@media (max-width: 425px) {
  .header {
    mix-blend-mode: difference;
  }
}
.header._active {
  mix-blend-mode: difference;
}
.header__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding-top: 12px;
  background: transparent;
}
.header__scroll-logo {
  position: fixed;
  top: 9px;
  left: 11px;
  z-index: 99999;
  width: 200px;
  height: 39.77px;
}
.header__menu-btn {
  position: fixed;
  top: 9px;
  right: 12px;
  z-index: 10;
  height: 48px;
  margin-top: -12px;
  margin-right: 1px;
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
  text-transform: lowercase;
  letter-spacing: -0.03em;
  transition: 0.3s;
  color: #fff;
  mix-blend-mode: difference;
}
@media (min-width: 90.75em) {
  .header__menu-btn {
    font-size: 3rem;
  }
}
@media (min-width: 20em) and (max-width: 90.75em) {
  @supports (font-size: clamp( 2rem , 1.7173144876rem  +  1.4134275618vw , 3rem )) {
    .header__menu-btn {
      font-size: clamp( 2rem , 1.7173144876rem  +  1.4134275618vw , 3rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.7173144876rem  +  1.4134275618vw , 3rem )) {
    .header__menu-btn {
      font-size: calc(2rem + 1 * (100vw - 20rem) / 70.75);
    }
  }
}
@media (max-width: 20em) {
  .header__menu-btn {
    font-size: 2rem;
  }
}
.header__menu-btn:hover {
  color: #e35e59;
}
.header__title {
  display: none;
}
.logo {
  position: fixed;
  top: 9px;
  left: 11px;
  width: 200px;
  height: 39.77px;
  z-index: 9;
}
@media (max-width: 425px) {
  .logo {
    display: none;
  }
}
.logo-icon {
  position: fixed;
  top: 9px;
  left: 11px;
  display: none;
}
@media (max-width: 425px) {
  .logo-icon {
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36.35px;
  }
}
.logo-icon--scroll {
  top: 12px;
  left: 12px;
}
@media (max-width: 425px) {
  .logo-icon--scroll {
    top: 9px;
    left: 11px;
  }
}
@media (max-width: 425px) {
  .logo-icon {
    top: 9px;
    left: 11px;
  }
}
._test .header {
  mix-blend-mode: difference;
}
._test .header .logo{
  opacity: 0;
}
._test .header .scroll-logo{
  opacity: 1;
}
._news .header {
  mix-blend-mode: difference;
}
._news .header .logo{
  opacity: 0;
}
._news .header .scroll-logo{
  opacity: 1;
}
._rubrics .header {
  mix-blend-mode: difference;
}
._rubrics .header .logo{
  opacity: 0;
}
._rubrics .header .scroll-logo{
  opacity: 1;
}
._main .header .logo {
  position: relative;
  top: -2px;
  left: 0;
  width: 100%;
  max-width: 734px;
  height: 147px;
}
@media (min-width: 26.625em) and (max-width: 90em) {
  @supports (max-width: clamp( 14.25rem , 0.9637573964rem  +  49.9013806706vw , 45.875rem )) {
    ._main .header .logo {
      max-width: clamp( 14.25rem , 0.9637573964rem  +  49.9013806706vw , 45.875rem );
    }
  }
  @supports not (max-width: clamp( 14.25rem , 0.9637573964rem  +  49.9013806706vw , 45.875rem )) {
    ._main .header .logo {
      max-width: calc(14.25rem + 31.625 * (100vw - 26.625rem) / 63.375);
    }
  }
}
@media (min-width: 26.625em) and (max-width: 90em) {
  @supports (height: clamp( 2.860625rem , 0.2025887574rem  +  9.983234714vw , 9.1875rem )) {
    ._main .header .logo {
      height: clamp( 2.860625rem , 0.2025887574rem  +  9.983234714vw , 9.1875rem );
    }
  }
  @supports not (height: clamp( 2.860625rem , 0.2025887574rem  +  9.983234714vw , 9.1875rem )) {
    ._main .header .logo {
      height: calc(2.860625rem + 6.326875 * (100vw - 26.625rem) / 63.375);
    }
  }
}
@media (max-width: 425px) {
  ._main .header .logo {
    display: none;
  }
}
._main .header .logo-icon {
  position: relative;
}
@media (max-width: 425px) {
  ._main .header .logo-icon {
    position: fixed;
    top: 9px;
    left: 11px;
  }
}
._main .header__title {
  position: absolute;
  top: 457px;
  left: -104px;
  display: block;
  font-size: 18px;
  line-height: 18px;
  transform: rotate(270deg);
}
@media (min-width: 64em) and (max-width: 90em) {
  @supports (top: clamp( 23.75rem , 11.9038461538rem  +  18.5096153846vw , 28.5625rem )) {
    ._main .header__title {
      top: clamp( 23.75rem , 11.9038461538rem  +  18.5096153846vw , 28.5625rem );
    }
  }
  @supports not (top: clamp( 23.75rem , 11.9038461538rem  +  18.5096153846vw , 28.5625rem )) {
    ._main .header__title {
      top: calc(23.75rem + 4.8125 * (100vw - 64rem) / 26);
    }
  }
}
@media (max-width: 1024px) {
  ._main .header__title {
    display: none;
  }
}
.scroll-logo {
  z-index: -5;
  display: block;
  width: 200px;
  height: 39.77px;
  opacity: 0;
  transition: 0.3s;
}
.scroll-logo._active {
  z-index: 1;
  opacity: 1;
}
.logo-icon._scroll {
  top: -3px;
  left: 11px;
  display: none;
}
.logo-icon._scroll._active {
  z-index: -5;
  opacity: 0;
}
@media (max-width: 425px) {
  .croll-logo-ibg {
    display: none;
  }
}
.footer {
  min-height: 60px;
  margin-top: 144px;
}
.footer_up {
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.03em;
  transition: 0.3s;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .footer_up {
      font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .footer_up {
      font-size: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .footer_up {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .footer_up {
      line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .footer_up {
      line-height: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .footer_up {
    line-height: 2rem;
  }
}
@media (max-width: 768px) {
  .footer_up {
    margin-bottom: 12px;
  }
}
.footer_up:hover {
  color: #e35e59;
}
.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}
@media (max-width: 768px) {
  .footer__container {
    flex-direction: column;
  }
}
.footer__link {
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.03em;
  transition: 0.3s;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .footer__link {
      font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .footer__link {
      font-size: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .footer__link {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .footer__link {
      line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .footer__link {
      line-height: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .footer__link {
    line-height: 2rem;
  }
}
@media (max-width: 768px) {
  .footer__link {
    margin-bottom: 72px;
  }
}
.footer__link:hover {
  color: #e35e59;
}
.social {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .social {
    margin-bottom: 24px;
  }
}
.social__item:not(:last-child) {
  margin-right: 12px;
}
.social__link {
  display: block;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  transition: all 0.2s linear;
}
.social__link--instagram {
  background: url("/img/icons/instagram.svg") center/cover no-repeat;
}
.social__link--instagram:hover {
  background: url("/img/icons/instagram-red.svg") center/cover no-repeat;
}
.social__link--facebook {
  background: url("/img/icons/facebook.svg") center/cover no-repeat;
}
.social__link--facebook:hover {
  background: url("/img/icons/facebook-red.svg") center/cover no-repeat;
}
.social__link--telegram {
  background: url("/img/icons/telegram.svg") center/cover no-repeat;
}
.social__link--telegram:hover {
  background: url("/img/icons/telegram-red.svg") center/cover no-repeat;
}
.social__link--zen {
  background: url("/img/icons/zen-black.svg") center/cover no-repeat;
}
.social__link--zen:hover {
  background: url("/img/icons/zen-red.svg") center/cover no-repeat;
}
._about .footer {
  margin-top: 43px;
}
@media (min-width: 20em) and (max-width: 48em) {
  @supports (margin-top: clamp( 1.25rem , 4.25rem  +  -6.25vw , 3rem )) {
    ._about .footer {
      margin-top: clamp( 1.25rem , 4.25rem  +  -6.25vw , 3rem );
    }
  }
  @supports not (margin-top: clamp( 1.25rem , 4.25rem  +  -6.25vw , 3rem )) {
    ._about .footer {
      margin-top: calc(3rem + -1.75 * (100vw - 20rem) / 28);
    }
  }
}
@media (max-width: 20em) {
  ._about .footer {
    margin-top: 3rem;
  }
}
._about .footer_up {
  display: none;
}
._about .footer__link {
  display: none;
}
._about .footer__container {
  justify-content: flex-end;
}
._news .footer {
  margin-top: 0;
}
@media (max-width: 768px) {
  ._news .footer {
    margin-top: -24px;
  }
}
._news .footer_up {
  opacity: 0;
  z-index: -1;
  position: fixed;
  bottom: 20px;
  transition: all 0.3s ease;
}
@media (max-width: 768px) {
  ._news .footer_up {
    display: none;
  }
}
._news .footer_up._active {
  opacity: 1;
  z-index: 9;
}
.page {
  padding-top: 12px;
}
.page__first-screen {
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .page__first-screen {
    margin-bottom: 36px;
  }
}
@media (max-width: 768px) {
  .first-screen__container {
    padding: 0;
  }
}
.first-screen__card {
  margin-left: 120px;
}
@media (min-width: 47.9375em) and (max-width: 90em) {
  @supports (margin-left: clamp( 0.00000625rem , -8.5475349183rem  +  17.8305943536vw , 7.5rem )) {
    .first-screen__card {
      margin-left: clamp( 0.00000625rem , -8.5475349183rem  +  17.8305943536vw , 7.5rem );
    }
  }
  @supports not (margin-left: clamp( 0.00000625rem , -8.5475349183rem  +  17.8305943536vw , 7.5rem )) {
    .first-screen__card {
      margin-left: calc(0.00000625rem + 7.5 * (100vw - 47.9375rem) / 42.0625);
    }
  }
}
@media (max-width: 768px) {
  .first-screen__card {
    flex-direction: column;
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .first-screen .card__tag {
    padding: 0 12px;
  }
}
@media (max-width: 768px) {
  .first-screen .card__title {
    padding: 0 12px;
  }
}
.card {
  display: flex;
}
.card:hover .card__title,
.card:hover .card__tag,
.card:hover .card__text {
  color: #e35e59;
}
.card__img-ibg {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 15px;
}
.card__tag {
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  transition: 0.3s;
}
@media (min-width: 90.75em) {
  .card__tag {
    font-size: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90.75em) {
  @supports (font-size: clamp( 0.875rem , 0.8043286219rem  +  0.3533568905vw , 1.125rem )) {
    .card__tag {
      font-size: clamp( 0.875rem , 0.8043286219rem  +  0.3533568905vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8043286219rem  +  0.3533568905vw , 1.125rem )) {
    .card__tag {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70.75);
    }
  }
}
@media (max-width: 20em) {
  .card__tag {
    font-size: 0.875rem;
  }
}
@media (max-width: 768px) {
  .card__tag {
    margin-bottom: 10px;
  }
}
.card__title {
  margin-bottom: 15px;
  font-size: 60px;
  line-height: 60px;
  font-family: Maregraphe Display;
  letter-spacing: -0.03em;
  transition: 0.3s;
}
@media (min-width: 90.75em) {
  .card__title {
    font-size: 3.75rem;
  }
}
@media (min-width: 20em) and (max-width: 90.75em) {
  @supports (font-size: clamp( 2.25rem , 1.8259717314rem  +  2.1201413428vw , 3.75rem )) {
    .card__title {
      font-size: clamp( 2.25rem , 1.8259717314rem  +  2.1201413428vw , 3.75rem );
    }
  }
  @supports not (font-size: clamp( 2.25rem , 1.8259717314rem  +  2.1201413428vw , 3.75rem )) {
    .card__title {
      font-size: calc(2.25rem + 1.5 * (100vw - 20rem) / 70.75);
    }
  }
}
@media (max-width: 20em) {
  .card__title {
    font-size: 2.25rem;
  }
}
@media (min-width: 90.75em) {
  .card__title {
    line-height: 3.75rem;
  }
}
@media (min-width: 20em) and (max-width: 90.75em) {
  @supports (line-height: clamp( 2.25rem , 1.8259717314rem  +  2.1201413428vw , 3.75rem )) {
    .card__title {
      line-height: clamp( 2.25rem , 1.8259717314rem  +  2.1201413428vw , 3.75rem );
    }
  }
  @supports not (line-height: clamp( 2.25rem , 1.8259717314rem  +  2.1201413428vw , 3.75rem )) {
    .card__title {
      line-height: calc(2.25rem + 1.5 * (100vw - 20rem) / 70.75);
    }
  }
}
@media (max-width: 20em) {
  .card__title {
    line-height: 2.25rem;
  }
}
.card__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  transition: 0.3s;
}
@media (min-width: 90.75em) {
  .card__text {
    font-size: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90.75em) {
  @supports (font-size: clamp( 0.875rem , 0.8043286219rem  +  0.3533568905vw , 1.125rem )) {
    .card__text {
      font-size: clamp( 0.875rem , 0.8043286219rem  +  0.3533568905vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8043286219rem  +  0.3533568905vw , 1.125rem )) {
    .card__text {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70.75);
    }
  }
}
@media (max-width: 20em) {
  .card__text {
    font-size: 0.875rem;
  }
}
.card--first-screen {
  display: flex;
}
@media (max-width: 768px) {
  .card--first-screen {
    margin-top: -12px;
  }
}
.card--first-screen .card__img-ibg {
  max-width: 948px;
  height: 576px;
  margin-right: 12px;
  margin-bottom: 0;
}
@media (min-width: 48em) and (max-width: 90em) {
  @supports (max-width: clamp( 26.25rem , -11.4642857143rem  +  78.5714285714vw , 59.25rem )) {
    .card--first-screen .card__img-ibg {
      max-width: clamp( 26.25rem , -11.4642857143rem  +  78.5714285714vw , 59.25rem );
    }
  }
  @supports not (max-width: clamp( 26.25rem , -11.4642857143rem  +  78.5714285714vw , 59.25rem )) {
    .card--first-screen .card__img-ibg {
      max-width: calc(26.25rem + 33 * (100vw - 48rem) / 42);
    }
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 18.5rem , 13.5rem  +  25vw , 36rem )) {
    .card--first-screen .card__img-ibg {
      height: clamp( 18.5rem , 13.5rem  +  25vw , 36rem );
    }
  }
  @supports not (height: clamp( 18.5rem , 13.5rem  +  25vw , 36rem )) {
    .card--first-screen .card__img-ibg {
      height: calc(18.5rem + 17.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card--first-screen .card__img-ibg {
    height: 18.5rem;
  }
}
@media (max-width: 768px) {
  .card--first-screen .card__img-ibg {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.card--first-screen .card__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 348px;
}
@media (max-width: 768px) {
  .card--first-screen .card__inner {
    max-width: 100%;
  }
}
.card--first-screen .card__tag {
  margin-bottom: -6px;
}
@media (max-width: 767px) {
  .card--first-screen .card__tag {
    margin-bottom: -13px;
  }
}
.card--first-screen .card__title {
  position: relative;
  bottom: -20px;
}
.card--first-screen-bg .card__img-ibg {
  position: relative;
  background: #e7fe59;
}
.card--first-screen-bg .card__img-ibg img {
  position: absolute;
  top: unset;
  left: unset;
  right: 12px;
  bottom: 12px;
  width: 396px;
  height: 267px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (width: clamp( 12.9375rem , 9.5625rem  +  16.875vw , 24.75rem )) {
    .card--first-screen-bg .card__img-ibg img {
      width: clamp( 12.9375rem , 9.5625rem  +  16.875vw , 24.75rem );
    }
  }
  @supports not (width: clamp( 12.9375rem , 9.5625rem  +  16.875vw , 24.75rem )) {
    .card--first-screen-bg .card__img-ibg img {
      width: calc(12.9375rem + 11.8125 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card--first-screen-bg .card__img-ibg img {
    width: 12.9375rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 10.25rem , 8.4107142857rem  +  9.1964285714vw , 16.6875rem )) {
    .card--first-screen-bg .card__img-ibg img {
      height: clamp( 10.25rem , 8.4107142857rem  +  9.1964285714vw , 16.6875rem );
    }
  }
  @supports not (height: clamp( 10.25rem , 8.4107142857rem  +  9.1964285714vw , 16.6875rem )) {
    .card--first-screen-bg .card__img-ibg img {
      height: calc(10.25rem + 6.4375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card--first-screen-bg .card__img-ibg img {
    height: 10.25rem;
  }
}
.news {
  position: relative;
}
@media (max-width: 768px) {
  .news {
    padding-bottom: 2px;
  }
}
.news__title {
  position: absolute;
  top: 30px;
  font-size: 44px;
  line-height: 44px;
  font-family: Maregraphe Display;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .news__title {
      font-size: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem );
    }
  }
  @supports not (font-size: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .news__title {
      font-size: calc(2.25rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news__title {
    font-size: 2.25rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .news__title {
      line-height: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem );
    }
  }
  @supports not (line-height: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .news__title {
      line-height: calc(2.25rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news__title {
    line-height: 2.25rem;
  }
}
@media (max-width: 1023px) {
  .news__title {
    position: static;
    margin-bottom: 20px;
  }
}
@media (max-width: 1023px) and (min-width: 20em) and (max-width: 63.9375em) {
  @supports (margin-bottom: clamp( 1.25rem , 2.3413940256rem  +  -1.706970128vw , 2rem )) {
    .news__title {
      margin-bottom: clamp( 1.25rem , 2.3413940256rem  +  -1.706970128vw , 2rem );
    }
  }
  @supports not (margin-bottom: clamp( 1.25rem , 2.3413940256rem  +  -1.706970128vw , 2rem )) {
    .news__title {
      margin-bottom: calc(2rem + -0.75 * (100vw - 20rem) / 43.9375);
    }
  }
}
@media (max-width: 1023px) and (max-width: 20em) {
  .news__title {
    margin-bottom: 2rem;
  }
}
.news__content {
  display: flex;
  margin-bottom: 6px;
  margin-left: 240px;
}
@media (min-width: 78.125em) and (max-width: 91.875em) {
  @supports (margin-left: clamp( 0.00000625rem , -85.2272309659rem  +  109.0908636364vw , 15rem )) {
    .news__content {
      margin-left: clamp( 0.00000625rem , -85.2272309659rem  +  109.0908636364vw , 15rem );
    }
  }
  @supports not (margin-left: clamp( 0.00000625rem , -85.2272309659rem  +  109.0908636364vw , 15rem )) {
    .news__content {
      margin-left: calc(0.00000625rem + 15 * (100vw - 78.125rem) / 13.75);
    }
  }
}
@media (max-width: 1250px) {
  .news__content {
    margin-left: 0;
  }
}
.news__wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 12px;
}
@media (max-width: 1023px) {
  .news__wrap {
    width: 100%;
    position: static;
    margin-top: 12px;
    margin-right: -24px;
    margin-bottom: 17px;
    grid-column: 1/3;
  }
}
.news-list {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 228px;
  padding-top: 130px;
}
@media (max-width: 1023px) {
  .news-list {
    flex-direction: row;
    width: unset;
    margin-right: -12px;
    margin-bottom: 18px;
    margin-left: -12px;
    padding-top: 0;
    overflow: auto;
  }
  .news-list::-webkit-scrollbar {
    width: 0;
  }
}
.news-list__item {
  flex-shrink: 0;
}
@media (min-width: 64em) and (max-width: 90em) {
  @supports (width: clamp( 12.5rem , 8.1923076923rem  +  6.7307692308vw , 14.25rem )) {
    .news-list__item {
      width: clamp( 12.5rem , 8.1923076923rem  +  6.7307692308vw , 14.25rem );
    }
  }
  @supports not (width: clamp( 12.5rem , 8.1923076923rem  +  6.7307692308vw , 14.25rem )) {
    .news-list__item {
      width: calc(12.5rem + 1.75 * (100vw - 64rem) / 26);
    }
  }
}
.news-list__item:not(:last-child) {
  margin-bottom: 36px;
}
@media (max-width: 1023px) {
  .news-list__item {
    width: 193px;
    margin-bottom: 0;
  }
  .news-list__item:first-child {
    margin-left: 12px;
  }
  .news-list__item:last-child {
    margin-right: 12px;
  }
  .news-list__item:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 0;
  }
}
.news-list__link:hover .news-list__title,
.news-list__link:hover .news-list__data {
  color: #e35e59;
}
.news-list__data {
  display: block;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: Loos Normal;
  transition: 0.3s;
}
.news-list__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  transition: 0.3s;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .news-list__title {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .news-list__title {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-list__title {
    font-size: 0.875rem;
  }
}
@media (max-width: 768px) {
  .news-cards {
    width: 100%;
  }
}
.news-cards__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media (max-width: 1023px) {
  .news-cards__wrapper {
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .news-cards__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0;
  }
}
@media (max-width: 768px) {
  .news-cards__wrapper .card:not(:last-child) {
    margin-bottom: 12px;
  }
}
.news-cards__wrapper .card:nth-child(3n+1) {
  flex-flow: row-reverse;
  margin-bottom: 12px;
  grid-column: 1/3;
}
@media (min-width: 20em) and (max-width: 64em) {
  @supports (margin-bottom: clamp( 0.75rem , 2.3863636364rem  +  -2.5568181818vw , 1.875rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) {
      margin-bottom: clamp( 0.75rem , 2.3863636364rem  +  -2.5568181818vw , 1.875rem );
    }
  }
  @supports not (margin-bottom: clamp( 0.75rem , 2.3863636364rem  +  -2.5568181818vw , 1.875rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) {
      margin-bottom: calc(1.875rem + -1.125 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__wrapper .card:nth-child(3n+1) {
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 1023px) {
  .news-cards__wrapper .card:nth-child(3n+1) {
    width: 100%;
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .news-cards__wrapper .card:nth-child(3n+1) {
    display: flex;
    flex-direction: column;
  }
}
.news-cards__wrapper .card:nth-child(3n+1) .card__inner {
  margin-right: 25px;
}
@media (min-width: 64em) and (max-width: 81.25em) {
  @supports (max-width: clamp( 20rem , -0.4057971014rem  +  31.884057971vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__inner {
      max-width: clamp( 20rem , -0.4057971014rem  +  31.884057971vw , 25.5rem );
    }
  }
  @supports not (max-width: clamp( 20rem , -0.4057971014rem  +  31.884057971vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__inner {
      max-width: calc(20rem + 5.5 * (100vw - 64rem) / 17.25);
    }
  }
}
@media (min-width: 48.0625em) and (max-width: 63.9375em) {
  @supports (max-width: clamp( 18.5rem , -2.6929133858rem  +  44.094488189vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__inner {
      max-width: clamp( 18.5rem , -2.6929133858rem  +  44.094488189vw , 25.5rem );
    }
  }
  @supports not (max-width: clamp( 18.5rem , -2.6929133858rem  +  44.094488189vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__inner {
      max-width: calc(18.5rem + 7 * (100vw - 48.0625rem) / 15.875);
    }
  }
}
@media (min-width: 64em) and (max-width: 81.25em) {
  @supports (width: clamp( 20rem , -0.4057971014rem  +  31.884057971vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__inner {
      width: clamp( 20rem , -0.4057971014rem  +  31.884057971vw , 25.5rem );
    }
  }
  @supports not (width: clamp( 20rem , -0.4057971014rem  +  31.884057971vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__inner {
      width: calc(20rem + 5.5 * (100vw - 64rem) / 17.25);
    }
  }
}
@media (min-width: 48.0625em) and (max-width: 63.9375em) {
  @supports (width: clamp( 18.5rem , -2.6929133858rem  +  44.094488189vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__inner {
      width: clamp( 18.5rem , -2.6929133858rem  +  44.094488189vw , 25.5rem );
    }
  }
  @supports not (width: clamp( 18.5rem , -2.6929133858rem  +  44.094488189vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__inner {
      width: calc(18.5rem + 7 * (100vw - 48.0625rem) / 15.875);
    }
  }
}
.news-cards__wrapper .card:nth-child(3n+1) .card__title {
  font-weight: 300;
  font-size: 60px;
  line-height: 60px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.05em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__title {
      font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__title {
      font-size: calc(2rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__wrapper .card:nth-child(3n+1) .card__title {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__title {
      line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__title {
      line-height: calc(2rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__wrapper .card:nth-child(3n+1) .card__title {
    line-height: 2rem;
  }
}
@media (max-width: 768px) {
  .news-cards__wrapper .card:nth-child(3n+1) .card__tag {
    margin-bottom: 7px;
  }
}
.news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg {
  width: 408px;
  height: 577px;
  margin-bottom: 0;
}
@media (min-width: 64em) and (max-width: 81.25em) {
  @supports (width: clamp( 25.6875rem , -1.4429347826rem  +  42.3913043478vw , 33rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg {
      width: clamp( 25.6875rem , -1.4429347826rem  +  42.3913043478vw , 33rem );
    }
  }
  @supports not (width: clamp( 25.6875rem , -1.4429347826rem  +  42.3913043478vw , 33rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg {
      width: calc(25.6875rem + 7.3125 * (100vw - 64rem) / 17.25);
    }
  }
}
@media (min-width: 48.0625em) and (max-width: 63.9375em) {
  @supports (width: clamp( 26.25rem , -0.6195866142rem  +  55.905511811vw , 35.125rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg {
      width: clamp( 26.25rem , -0.6195866142rem  +  55.905511811vw , 35.125rem );
    }
  }
  @supports not (width: clamp( 26.25rem , -0.6195866142rem  +  55.905511811vw , 35.125rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg {
      width: calc(26.25rem + 8.875 * (100vw - 48.0625rem) / 15.875);
    }
  }
}
@media (min-width: 48em) and (max-width: 90em) {
  @supports (height: clamp( 26.25rem , 15.0357142857rem  +  23.3630952381vw , 36.0625rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg {
      height: clamp( 26.25rem , 15.0357142857rem  +  23.3630952381vw , 36.0625rem );
    }
  }
  @supports not (height: clamp( 26.25rem , 15.0357142857rem  +  23.3630952381vw , 36.0625rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg {
      height: calc(26.25rem + 9.8125 * (100vw - 48rem) / 42);
    }
  }
}
.news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg img {
  right: 0;
  margin-left: auto;
}
@media (min-width: 48em) and (max-width: 90em) {
  @supports (width: clamp( 20rem , 13.7142857143rem  +  13.0952380952vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg img {
      width: clamp( 20rem , 13.7142857143rem  +  13.0952380952vw , 25.5rem );
    }
  }
  @supports not (width: clamp( 20rem , 13.7142857143rem  +  13.0952380952vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg img {
      width: calc(20rem + 5.5 * (100vw - 48rem) / 42);
    }
  }
}
@media (max-width: 768px) {
  .news-cards__wrapper .card:nth-child(3n+1) .card__img-ibg {
    width: 100%;
    height: 420px;
    margin-bottom: 16px;
  }
}
.news-cards__wrapper .card:nth-child(3n+2) {
  display: flex;
  flex-direction: column;
  width: 408px;
  margin-right: 12px;
  grid-column: 1/2;
}
@media (min-width: 64em) and (max-width: 81.25em) {
  @supports (width: clamp( 20rem , -0.4057971014rem  +  31.884057971vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) {
      width: clamp( 20rem , -0.4057971014rem  +  31.884057971vw , 25.5rem );
    }
  }
  @supports not (width: clamp( 20rem , -0.4057971014rem  +  31.884057971vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) {
      width: calc(20rem + 5.5 * (100vw - 64rem) / 17.25);
    }
  }
}
@media (min-width: 48.0625em) and (max-width: 63.9375em) {
  @supports (width: clamp( 18.5rem , -2.6929133858rem  +  44.094488189vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) {
      width: clamp( 18.5rem , -2.6929133858rem  +  44.094488189vw , 25.5rem );
    }
  }
  @supports not (width: clamp( 18.5rem , -2.6929133858rem  +  44.094488189vw , 25.5rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) {
      width: calc(18.5rem + 7 * (100vw - 48.0625rem) / 15.875);
    }
  }
}
@media (max-width: 1440px) {
  .news-cards__wrapper .card:nth-child(3n+2) {
    justify-self: end;
  }
}
@media (max-width: 768px) {
  .news-cards__wrapper .card:nth-child(3n+2) {
    width: 100%;
    margin-bottom: 0;
  }
}
.news-cards__wrapper .card:nth-child(3n+2) .card__img-ibg {
  max-width: 408px;
  height: 228px;
}
@media (max-width: 768px) {
  .news-cards__wrapper .card:nth-child(3n+2) .card__img-ibg {
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 20em) and (max-width: 48em) {
  @supports (height: clamp( 10.3125rem , -1.0714285714rem  +  56.9196428571vw , 26.25rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) .card__img-ibg {
      height: clamp( 10.3125rem , -1.0714285714rem  +  56.9196428571vw , 26.25rem );
    }
  }
  @supports not (height: clamp( 10.3125rem , -1.0714285714rem  +  56.9196428571vw , 26.25rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) .card__img-ibg {
      height: calc(10.3125rem + 15.9375 * (100vw - 20rem) / 28);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__wrapper .card:nth-child(3n+2) .card__img-ibg {
    height: 10.3125rem;
  }
}
.news-cards__wrapper .card:nth-child(3n+2) .card__title {
  font-size: 44px;
  line-height: 44px;
  font-family: Maregraphe Display;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) .card__title {
      font-size: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem );
    }
  }
  @supports not (font-size: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) .card__title {
      font-size: calc(2.25rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__wrapper .card:nth-child(3n+2) .card__title {
    font-size: 2.25rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) .card__title {
      line-height: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem );
    }
  }
  @supports not (line-height: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .news-cards__wrapper .card:nth-child(3n+2) .card__title {
      line-height: calc(2.25rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__wrapper .card:nth-child(3n+2) .card__title {
    line-height: 2.25rem;
  }
}
.news-cards__wrapper .card:nth-child(3n+3) {
  display: flex;
  flex-direction: column;
  width: 528px;
  grid-column: 2/3;
}
@media (min-width: 64em) and (max-width: 81.25em) {
  @supports (width: clamp( 25.6875rem , -1.4429347826rem  +  42.3913043478vw , 33rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) {
      width: clamp( 25.6875rem , -1.4429347826rem  +  42.3913043478vw , 33rem );
    }
  }
  @supports not (width: clamp( 25.6875rem , -1.4429347826rem  +  42.3913043478vw , 33rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) {
      width: calc(25.6875rem + 7.3125 * (100vw - 64rem) / 17.25);
    }
  }
}
@media (min-width: 48.0625em) and (max-width: 63.9375em) {
  @supports (width: clamp( 26.25rem , -0.6195866142rem  +  55.905511811vw , 35.125rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) {
      width: clamp( 26.25rem , -0.6195866142rem  +  55.905511811vw , 35.125rem );
    }
  }
  @supports not (width: clamp( 26.25rem , -0.6195866142rem  +  55.905511811vw , 35.125rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) {
      width: calc(26.25rem + 8.875 * (100vw - 48.0625rem) / 15.875);
    }
  }
}
@media (max-width: 1440px) {
  .news-cards__wrapper .card:nth-child(3n+3) {
    justify-self: end;
  }
}
@media (max-width: 768px) {
  .news-cards__wrapper .card:nth-child(3n+3) {
    width: 100%;
    min-height: 165px;
  }
}
.news-cards__wrapper .card:nth-child(3n+3) .card__img-ibg {
  max-width: 528px;
  height: 372px;
}
@media (max-width: 1023px) {
  .news-cards__wrapper .card:nth-child(3n+3) .card__img-ibg {
    width: 100%;
    max-width: 100%;
  }
}
@media (min-width: 20em) and (max-width: 48em) {
  @supports (height: clamp( 13rem , 3.5357142857rem  +  47.3214285714vw , 26.25rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) .card__img-ibg {
      height: clamp( 13rem , 3.5357142857rem  +  47.3214285714vw , 26.25rem );
    }
  }
  @supports not (height: clamp( 13rem , 3.5357142857rem  +  47.3214285714vw , 26.25rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) .card__img-ibg {
      height: calc(13rem + 13.25 * (100vw - 20rem) / 28);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__wrapper .card:nth-child(3n+3) .card__img-ibg {
    height: 13rem;
  }
}
.news-cards__wrapper .card:nth-child(3n+3) .card__title {
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) .card__title {
      font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) .card__title {
      font-size: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__wrapper .card:nth-child(3n+3) .card__title {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) .card__title {
      line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .news-cards__wrapper .card:nth-child(3n+3) .card__title {
      line-height: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__wrapper .card:nth-child(3n+3) .card__title {
    line-height: 2rem;
  }
}
.news-cards__wrapper .card__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 516px;
}
@media (max-width: 768px) {
  .news-cards__wrapper .card__inner {
    max-width: 100%;
  }
}
.news-cards__wrapper .card__title {
  margin-bottom: 9px;
  font-weight: 300;
  font-family: Loos ExtraWide;
  letter-spacing: -0.05em;
}
.news-cards__wrapper .card__text {
  margin-bottom: 34px;
}
.news-cards__more {
  display: flex;
  margin: 0 auto;
  font-weight: 300;
  font-size: 60px;
  line-height: 60px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.05em;
  transition: 0.3s;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .news-cards__more {
      font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .news-cards__more {
      font-size: calc(2rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__more {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .news-cards__more {
      line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .news-cards__more {
      line-height: calc(2rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-cards__more {
    line-height: 2rem;
  }
}
.news-cards__more:hover {
  color: #e35e59;
}
.ecology {
  position: relative;
  margin-top: -12px;
  padding-bottom: 12px;
}
.ecology:before {
  content: "";
  position: absolute;
  top: -52px;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 55px;
  background-color: #f4d4fc;
}
.ecology__content {
  display: grid;
  gap: 12px 252px;
  grid-template-columns: 1fr 1fr;
}
@media (min-width: 64em) and (max-width: 91.875em) {
  @supports (column-gap: clamp( 0.625rem , -34.1014573991rem  +  54.2600896861vw , 15.75rem )) {
    .ecology__content {
      column-gap: clamp( 0.625rem , -34.1014573991rem  +  54.2600896861vw , 15.75rem );
    }
  }
  @supports not (column-gap: clamp( 0.625rem , -34.1014573991rem  +  54.2600896861vw , 15.75rem )) {
    .ecology__content {
      column-gap: calc(0.625rem + 15.125 * (100vw - 64rem) / 27.875);
    }
  }
}
@media (max-width: 1023px) {
  .ecology__content {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
@media (max-width: 1023px) {
  .ecology__content .card:not(:last-child) {
    margin-bottom: 40px;
  }
}
.ecology__content .card:nth-child(3n+1) {
  width: 768px;
  grid-column: 1/2;
}
@media (min-width: 64em) and (max-width: 90em) {
  @supports (width: clamp( 38.1875rem , 14.0336538462rem  +  37.7403846154vw , 48rem )) {
    .ecology__content .card:nth-child(3n+1) {
      width: clamp( 38.1875rem , 14.0336538462rem  +  37.7403846154vw , 48rem );
    }
  }
  @supports not (width: clamp( 38.1875rem , 14.0336538462rem  +  37.7403846154vw , 48rem )) {
    .ecology__content .card:nth-child(3n+1) {
      width: calc(38.1875rem + 9.8125 * (100vw - 64rem) / 26);
    }
  }
}
@media (max-width: 1023px) {
  .ecology__content .card:nth-child(3n+1) {
    flex-direction: row-reverse;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .ecology__content .card:nth-child(3n+1) {
    flex-direction: column;
  }
}
.ecology__content .card:nth-child(3n+1) .card__img-ibg {
  z-index: 1;
  width: 348px;
  height: 500px;
  margin-right: 12px;
  margin-bottom: 0;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (width: clamp( 18.5rem , 17.5714285714rem  +  4.6428571429vw , 21.75rem )) {
    .ecology__content .card:nth-child(3n+1) .card__img-ibg {
      width: clamp( 18.5rem , 17.5714285714rem  +  4.6428571429vw , 21.75rem );
    }
  }
  @supports not (width: clamp( 18.5rem , 17.5714285714rem  +  4.6428571429vw , 21.75rem )) {
    .ecology__content .card:nth-child(3n+1) .card__img-ibg {
      width: calc(18.5rem + 3.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+1) .card__img-ibg {
    width: 18.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 26.25rem , 24.8214285714rem  +  7.1428571429vw , 31.25rem )) {
    .ecology__content .card:nth-child(3n+1) .card__img-ibg {
      height: clamp( 26.25rem , 24.8214285714rem  +  7.1428571429vw , 31.25rem );
    }
  }
  @supports not (height: clamp( 26.25rem , 24.8214285714rem  +  7.1428571429vw , 31.25rem )) {
    .ecology__content .card:nth-child(3n+1) .card__img-ibg {
      height: calc(26.25rem + 5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+1) .card__img-ibg {
    height: 26.25rem;
  }
}
@media (max-width: 768px) {
  .ecology__content .card:nth-child(3n+1) .card__img-ibg {
    margin-bottom: 12px;
  }
}
.ecology__content .card:nth-child(3n+1) .card__title {
  margin-bottom: -7px;
  padding-right: 0;
  padding-left: 0;
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.8928571429rem  +  0.5357142857vw , 2.375rem )) {
    .ecology__content .card:nth-child(3n+1) .card__title {
      font-size: clamp( 2rem , 1.8928571429rem  +  0.5357142857vw , 2.375rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.8928571429rem  +  0.5357142857vw , 2.375rem )) {
    .ecology__content .card:nth-child(3n+1) .card__title {
      font-size: calc(2rem + 0.375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+1) .card__title {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.8928571429rem  +  0.5357142857vw , 2.375rem )) {
    .ecology__content .card:nth-child(3n+1) .card__title {
      line-height: clamp( 2rem , 1.8928571429rem  +  0.5357142857vw , 2.375rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.8928571429rem  +  0.5357142857vw , 2.375rem )) {
    .ecology__content .card:nth-child(3n+1) .card__title {
      line-height: calc(2rem + 0.375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+1) .card__title {
    line-height: 2rem;
  }
}
.ecology__content .card:nth-child(3n+2) {
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  width: 408px;
  grid-column: 2/3;
}
@media (min-width: 48em) and (max-width: 90em) {
  @supports (width: clamp( 22.875rem , 19.875rem  +  6.25vw , 25.5rem )) {
    .ecology__content .card:nth-child(3n+2) {
      width: clamp( 22.875rem , 19.875rem  +  6.25vw , 25.5rem );
    }
  }
  @supports not (width: clamp( 22.875rem , 19.875rem  +  6.25vw , 25.5rem )) {
    .ecology__content .card:nth-child(3n+2) {
      width: calc(22.875rem + 2.625 * (100vw - 48rem) / 42);
    }
  }
}
@media (max-width: 1023px) {
  .ecology__content .card:nth-child(3n+2) {
    display: flex;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .ecology__content .card:nth-child(3n+2) {
    flex-direction: column;
  }
}
.ecology__content .card:nth-child(3n+2) .card__img-ibg {
  z-index: 1;
  max-width: 408px;
  height: 230px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 10.5rem , 9.3928571429rem  +  5.5357142857vw , 14.375rem )) {
    .ecology__content .card:nth-child(3n+2) .card__img-ibg {
      height: clamp( 10.5rem , 9.3928571429rem  +  5.5357142857vw , 14.375rem );
    }
  }
  @supports not (height: clamp( 10.5rem , 9.3928571429rem  +  5.5357142857vw , 14.375rem )) {
    .ecology__content .card:nth-child(3n+2) .card__img-ibg {
      height: calc(10.5rem + 3.875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+2) .card__img-ibg {
    height: 10.5rem;
  }
}
.ecology__content .card:nth-child(3n+2) .card__title {
  padding-right: 0;
  padding-left: 0;
  font-size: 44px;
  line-height: 44px;
  font-family: Maregraphe Display;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.7857142857rem  +  1.0714285714vw , 2.75rem )) {
    .ecology__content .card:nth-child(3n+2) .card__title {
      font-size: clamp( 2rem , 1.7857142857rem  +  1.0714285714vw , 2.75rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.7857142857rem  +  1.0714285714vw , 2.75rem )) {
    .ecology__content .card:nth-child(3n+2) .card__title {
      font-size: calc(2rem + 0.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+2) .card__title {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.7857142857rem  +  1.0714285714vw , 2.75rem )) {
    .ecology__content .card:nth-child(3n+2) .card__title {
      line-height: clamp( 2rem , 1.7857142857rem  +  1.0714285714vw , 2.75rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.7857142857rem  +  1.0714285714vw , 2.75rem )) {
    .ecology__content .card:nth-child(3n+2) .card__title {
      line-height: calc(2rem + 0.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+2) .card__title {
    line-height: 2rem;
  }
}
@media (max-width: 1023px) {
  .ecology__content .card:nth-child(3n+2) .card__title {
    margin-bottom: 0;
  }
}
.ecology__content .card:nth-child(3n+3) {
  display: flex;
  flex-direction: row-reverse;
  grid-column: 1/3;
}
@media (max-width: 768px) {
  .ecology__content .card:nth-child(3n+3) {
    flex-direction: column;
  }
}
.ecology__content .card:nth-child(3n+3) .card__img-ibg {
  width: 1008px;
  height: 708px;
  margin-bottom: 0;
}
@media (min-width: 48em) and (max-width: 90em) {
  @supports (width: clamp( 24.75rem , 2.4642857143rem  +  46.4285714286vw , 44.25rem )) {
    .ecology__content .card:nth-child(3n+3) .card__img-ibg {
      width: clamp( 24.75rem , 2.4642857143rem  +  46.4285714286vw , 44.25rem );
    }
  }
  @supports not (width: clamp( 24.75rem , 2.4642857143rem  +  46.4285714286vw , 44.25rem )) {
    .ecology__content .card:nth-child(3n+3) .card__img-ibg {
      width: calc(24.75rem + 19.5 * (100vw - 48rem) / 42);
    }
  }
}
@media (min-width: 20em) and (max-width: 47.9375em) {
  @supports (width: clamp( 18.5rem , 0.0659955257rem  +  92.1700223714vw , 44.25rem )) {
    .ecology__content .card:nth-child(3n+3) .card__img-ibg {
      width: clamp( 18.5rem , 0.0659955257rem  +  92.1700223714vw , 44.25rem );
    }
  }
  @supports not (width: clamp( 18.5rem , 0.0659955257rem  +  92.1700223714vw , 44.25rem )) {
    .ecology__content .card:nth-child(3n+3) .card__img-ibg {
      width: calc(18.5rem + 25.75 * (100vw - 20rem) / 27.9375);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+3) .card__img-ibg {
    width: 18.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 12.9375rem , 7.5625rem  +  26.875vw , 31.75rem )) {
    .ecology__content .card:nth-child(3n+3) .card__img-ibg {
      height: clamp( 12.9375rem , 7.5625rem  +  26.875vw , 31.75rem );
    }
  }
  @supports not (height: clamp( 12.9375rem , 7.5625rem  +  26.875vw , 31.75rem )) {
    .ecology__content .card:nth-child(3n+3) .card__img-ibg {
      height: calc(12.9375rem + 18.8125 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+3) .card__img-ibg {
    height: 12.9375rem;
  }
}
@media (max-width: 1400px) {
  .ecology__content .card:nth-child(3n+3) .card__img-ibg {
    margin-right: 12px;
  }
}
@media (max-width: 768px) {
  .ecology__content .card:nth-child(3n+3) .card__img-ibg {
    margin-right: 0;
  }
}
.ecology__content .card:nth-child(3n+3) .card__title {
  margin-bottom: -5px;
  padding-right: 0;
  padding-left: 0;
  margin-right: 12px;
  font-weight: 300;
  font-size: 60px;
  line-height: 60px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .ecology__content .card:nth-child(3n+3) .card__title {
      font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .ecology__content .card:nth-child(3n+3) .card__title {
      font-size: calc(2rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+3) .card__title {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .ecology__content .card:nth-child(3n+3) .card__title {
      line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .ecology__content .card:nth-child(3n+3) .card__title {
      line-height: calc(2rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+3) .card__title {
    line-height: 2rem;
  }
}
@media (max-width: 768px) {
  .ecology__content .card:nth-child(3n+3) .card__title {
    margin-right: 0;
  }
}
.ecology__content .card:nth-child(3n+3) .card__inner {
  justify-content: center;
  padding-top: 64px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-top: clamp( 0.75rem , -0.25rem  +  5vw , 4.25rem )) {
    .ecology__content .card:nth-child(3n+3) .card__inner {
      padding-top: clamp( 0.75rem , -0.25rem  +  5vw , 4.25rem );
    }
  }
  @supports not (padding-top: clamp( 0.75rem , -0.25rem  +  5vw , 4.25rem )) {
    .ecology__content .card:nth-child(3n+3) .card__inner {
      padding-top: calc(0.75rem + 3.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+3) .card__inner {
    padding-top: 0.75rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 0.00000625rem , 4.8214267857rem  +  -5.3571339286vw , 3.75rem )) {
    .ecology__content .card:nth-child(3n+3) .card__inner {
      padding-bottom: clamp( 0.00000625rem , 4.8214267857rem  +  -5.3571339286vw , 3.75rem );
    }
  }
  @supports not (padding-bottom: clamp( 0.00000625rem , 4.8214267857rem  +  -5.3571339286vw , 3.75rem )) {
    .ecology__content .card:nth-child(3n+3) .card__inner {
      padding-bottom: calc(3.75rem + -3.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .ecology__content .card:nth-child(3n+3) .card__inner {
    padding-bottom: 3.75rem;
  }
}
.ecology__content .card__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.cites {
  padding-top: 38px;
  padding-bottom: 85px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-top: clamp( 1.8125rem , 1.6517857143rem  +  0.8035714286vw , 2.375rem )) {
    .cites {
      padding-top: clamp( 1.8125rem , 1.6517857143rem  +  0.8035714286vw , 2.375rem );
    }
  }
  @supports not (padding-top: clamp( 1.8125rem , 1.6517857143rem  +  0.8035714286vw , 2.375rem )) {
    .cites {
      padding-top: calc(1.8125rem + 0.5625 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .cites {
    padding-top: 1.8125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 0.00000625rem , -1.5178491071rem  +  7.5892767857vw , 5.3125rem )) {
    .cites {
      padding-bottom: clamp( 0.00000625rem , -1.5178491071rem  +  7.5892767857vw , 5.3125rem );
    }
  }
  @supports not (padding-bottom: clamp( 0.00000625rem , -1.5178491071rem  +  7.5892767857vw , 5.3125rem )) {
    .cites {
      padding-bottom: calc(0.00000625rem + 5.3125 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .cites {
    padding-bottom: 0.00000625rem;
  }
}
.cites__title {
  margin-bottom: 93px;
  margin-left: 240px;
  font-weight: 300;
  font-size: 137px;
  line-height: 108px;
  font-family: Maregraphe Display;
  text-transform: uppercase;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 3rem , 1.4107142857rem  +  7.9464285714vw , 8.5625rem )) {
    .cites__title {
      font-size: clamp( 3rem , 1.4107142857rem  +  7.9464285714vw , 8.5625rem );
    }
  }
  @supports not (font-size: clamp( 3rem , 1.4107142857rem  +  7.9464285714vw , 8.5625rem )) {
    .cites__title {
      font-size: calc(3rem + 5.5625 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .cites__title {
    font-size: 3rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2.375rem , 1.125rem  +  6.25vw , 6.75rem )) {
    .cites__title {
      line-height: clamp( 2.375rem , 1.125rem  +  6.25vw , 6.75rem );
    }
  }
  @supports not (line-height: clamp( 2.375rem , 1.125rem  +  6.25vw , 6.75rem )) {
    .cites__title {
      line-height: calc(2.375rem + 4.375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .cites__title {
    line-height: 2.375rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-left: clamp( 0.00000625rem , -4.28570625rem  +  21.4285625vw , 15rem )) {
    .cites__title {
      margin-left: clamp( 0.00000625rem , -4.28570625rem  +  21.4285625vw , 15rem );
    }
  }
  @supports not (margin-left: clamp( 0.00000625rem , -4.28570625rem  +  21.4285625vw , 15rem )) {
    .cites__title {
      margin-left: calc(0.00000625rem + 15 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .cites__title {
    margin-left: 0.00000625rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-bottom: clamp( 1.9375rem , 0.8303571429rem  +  5.5357142857vw , 5.8125rem )) {
    .cites__title {
      margin-bottom: clamp( 1.9375rem , 0.8303571429rem  +  5.5357142857vw , 5.8125rem );
    }
  }
  @supports not (margin-bottom: clamp( 1.9375rem , 0.8303571429rem  +  5.5357142857vw , 5.8125rem )) {
    .cites__title {
      margin-bottom: calc(1.9375rem + 3.875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .cites__title {
    margin-bottom: 1.9375rem;
  }
}
.cites__content {
  display: grid;
  gap: 12px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(28px, 48px));
  grid-template-rows: minmax(200px, 492px) 254px 312px 166px 71px 228px 494px;
}
@media (max-width: 1024px) {
  .cites__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (max-width: 650px) {
  .cites__content {
    flex-direction: column;
  }
}
.card1 {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-left: 12px;
  background-color: #322740;
  grid-column: 8/18;
  grid-row: 1/3;
}
@media (max-width: 1024px) {
  .card1 {
    width: 49%;
    height: 662px;
  }
}
@media (max-width: 1024px) and (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card1 {
      height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem );
    }
  }
  @supports not (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card1 {
      height: calc(25.5rem + 15.875 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 1024px) and (max-width: 20em) {
  .card1 {
    height: 25.5rem;
  }
}
@media (max-width: 650px) {
  .card1 {
    width: 100%;
  }
}
.card1__img-ibg {
  max-width: 216px;
  height: 306px;
  margin-bottom: 119px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (max-width: clamp( 5.656875rem , 3.4159821429rem  +  11.2044642857vw , 13.5rem )) {
    .card1__img-ibg {
      max-width: clamp( 5.656875rem , 3.4159821429rem  +  11.2044642857vw , 13.5rem );
    }
  }
  @supports not (max-width: clamp( 5.656875rem , 3.4159821429rem  +  11.2044642857vw , 13.5rem )) {
    .card1__img-ibg {
      max-width: calc(5.656875rem + 7.843125 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card1__img-ibg {
    max-width: 5.656875rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 8rem , 4.8214285714rem  +  15.8928571429vw , 19.125rem )) {
    .card1__img-ibg {
      height: clamp( 8rem , 4.8214285714rem  +  15.8928571429vw , 19.125rem );
    }
  }
  @supports not (height: clamp( 8rem , 4.8214285714rem  +  15.8928571429vw , 19.125rem )) {
    .card1__img-ibg {
      height: calc(8rem + 11.125 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card1__img-ibg {
    height: 8rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-bottom: clamp( 3.6875rem , 2.6160714286rem  +  5.3571428571vw , 7.4375rem )) {
    .card1__img-ibg {
      margin-bottom: clamp( 3.6875rem , 2.6160714286rem  +  5.3571428571vw , 7.4375rem );
    }
  }
  @supports not (margin-bottom: clamp( 3.6875rem , 2.6160714286rem  +  5.3571428571vw , 7.4375rem )) {
    .card1__img-ibg {
      margin-bottom: calc(3.6875rem + 3.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card1__img-ibg {
    margin-bottom: 3.6875rem;
  }
}
.card1__title {
  margin-top: 14px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 60px;
  line-height: 48px;
  font-family: Loos ExtraWide;
  color: #f4d4fc;
  text-transform: uppercase;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .card1__title {
      font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .card1__title {
      font-size: calc(2rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card1__title {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.875rem , 1.5535714286rem  +  1.6071428571vw , 3rem )) {
    .card1__title {
      line-height: clamp( 1.875rem , 1.5535714286rem  +  1.6071428571vw , 3rem );
    }
  }
  @supports not (line-height: clamp( 1.875rem , 1.5535714286rem  +  1.6071428571vw , 3rem )) {
    .card1__title {
      line-height: calc(1.875rem + 1.125 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card1__title {
    line-height: 1.875rem;
  }
}
.card1__title span {
  display: block;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #f4d4fc;
}
.card1__text {
  max-width: 266px;
  padding-bottom: 72px;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  color: #f4d4fc;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .card1__text {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .card1__text {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card1__text {
    font-size: 0.875rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 0.875rem , -0.1607142857rem  +  5.1785714286vw , 4.5rem )) {
    .card1__text {
      padding-bottom: clamp( 0.875rem , -0.1607142857rem  +  5.1785714286vw , 4.5rem );
    }
  }
  @supports not (padding-bottom: clamp( 0.875rem , -0.1607142857rem  +  5.1785714286vw , 4.5rem )) {
    .card1__text {
      padding-bottom: calc(0.875rem + 3.625 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card1__text {
    padding-bottom: 0.875rem;
  }
}
.card2 {
  display: flex;
  flex-direction: column;
  padding: 16px 12px 12px;
  background-color: #296239;
  grid-column: 18/24;
  grid-row: 1/2;
}
@media (max-width: 1024px) {
  .card2 {
    width: 49%;
    height: 662px;
  }
}
@media (max-width: 1024px) and (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card2 {
      height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem );
    }
  }
  @supports not (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card2 {
      height: calc(25.5rem + 15.875 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 1024px) and (max-width: 20em) {
  .card2 {
    height: 25.5rem;
  }
}
@media (max-width: 650px) {
  .card2 {
    width: 100%;
  }
}
.card2__title {
  max-width: 215px;
  margin-bottom: 9px;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  font-family: Loos ExtraWide;
  color: #eab271;
  text-transform: uppercase;
}
.card2__img-ibg {
  max-width: 100%;
  height: 325px;
  margin-bottom: 12px;
}
@media (min-width: 64.0625em) and (max-width: 90em) {
  @supports (height: clamp( 18.75rem , 14.890813253rem  +  6.0240963855vw , 20.3125rem )) {
    .card2__img-ibg {
      height: clamp( 18.75rem , 14.890813253rem  +  6.0240963855vw , 20.3125rem );
    }
  }
  @supports not (height: clamp( 18.75rem , 14.890813253rem  +  6.0240963855vw , 20.3125rem )) {
    .card2__img-ibg {
      height: calc(18.75rem + 1.5625 * (100vw - 64.0625rem) / 25.9375);
    }
  }
}
@media (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 16.25rem , 11.5625rem  +  23.4375vw , 26.5625rem )) {
    .card2__img-ibg {
      height: clamp( 16.25rem , 11.5625rem  +  23.4375vw , 26.5625rem );
    }
  }
  @supports not (height: clamp( 16.25rem , 11.5625rem  +  23.4375vw , 26.5625rem )) {
    .card2__img-ibg {
      height: calc(16.25rem + 10.3125 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 20em) {
  .card2__img-ibg {
    height: 16.25rem;
  }
}
.card2__text {
  margin-bottom: 28px;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  color: #eab271;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .card2__text {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .card2__text {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card2__text {
    font-size: 0.875rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-bottom: clamp( 0.8125rem , 0.5446428571rem  +  1.3392857143vw , 1.75rem )) {
    .card2__text {
      margin-bottom: clamp( 0.8125rem , 0.5446428571rem  +  1.3392857143vw , 1.75rem );
    }
  }
  @supports not (margin-bottom: clamp( 0.8125rem , 0.5446428571rem  +  1.3392857143vw , 1.75rem )) {
    .card2__text {
      margin-bottom: calc(0.8125rem + 0.9375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card2__text {
    margin-bottom: 0.8125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .card2__text {
      line-height: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (line-height: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .card2__text {
      line-height: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card2__text {
    line-height: 0.875rem;
  }
}
.card3 {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #575ab5;
  grid-column: 5/11;
  grid-row: 3/5;
}
@media (max-width: 1200px) {
  .card3 {
    grid-column: 4/11;
  }
}
@media (max-width: 1024px) {
  .card3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49%;
    height: 662px;
  }
}
@media (max-width: 1024px) and (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card3 {
      height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem );
    }
  }
  @supports not (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card3 {
      height: calc(25.5rem + 15.875 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 1024px) and (max-width: 20em) {
  .card3 {
    height: 25.5rem;
  }
}
@media (max-width: 650px) {
  .card3 {
    width: 100%;
  }
}
.card3__title {
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  font-family: Loos ExtraWide;
  color: #fbf0e9;
  text-transform: uppercase;
}
.card3__img-ibg {
  margin-top: auto;
  width: 100%;
  height: 216px;
}
@media (min-width: 64.0625em) and (max-width: 90em) {
  @supports (height: clamp( 12.5rem , 10.0301204819rem  +  3.8554216867vw , 13.5rem )) {
    .card3__img-ibg {
      height: clamp( 12.5rem , 10.0301204819rem  +  3.8554216867vw , 13.5rem );
    }
  }
  @supports not (height: clamp( 12.5rem , 10.0301204819rem  +  3.8554216867vw , 13.5rem )) {
    .card3__img-ibg {
      height: calc(12.5rem + 1 * (100vw - 64.0625rem) / 25.9375);
    }
  }
}
@media (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 10.8125rem , 6.4943181818rem  +  21.5909090909vw , 20.3125rem )) {
    .card3__img-ibg {
      height: clamp( 10.8125rem , 6.4943181818rem  +  21.5909090909vw , 20.3125rem );
    }
  }
  @supports not (height: clamp( 10.8125rem , 6.4943181818rem  +  21.5909090909vw , 20.3125rem )) {
    .card3__img-ibg {
      height: calc(10.8125rem + 9.5 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 20em) {
  .card3__img-ibg {
    height: 10.8125rem;
  }
}
.card4 {
  padding: 12px;
  background-color: #669a80;
  grid-column: 11/18;
  grid-row: 3/6;
}
@media (max-width: 1024px) {
  .card4 {
    width: 49%;
    height: 662px;
  }
}
@media (max-width: 1024px) and (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card4 {
      height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem );
    }
  }
  @supports not (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card4 {
      height: calc(25.5rem + 15.875 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 1024px) and (max-width: 20em) {
  .card4 {
    height: 25.5rem;
  }
}
@media (max-width: 650px) {
  .card4 {
    width: 100%;
  }
}
.card4__img-ibg {
  width: 100%;
  height: 252px;
  margin-bottom: 116px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-bottom: clamp( 3.875rem , 2.9107142857rem  +  4.8214285714vw , 7.25rem )) {
    .card4__img-ibg {
      margin-bottom: clamp( 3.875rem , 2.9107142857rem  +  4.8214285714vw , 7.25rem );
    }
  }
  @supports not (margin-bottom: clamp( 3.875rem , 2.9107142857rem  +  4.8214285714vw , 7.25rem )) {
    .card4__img-ibg {
      margin-bottom: calc(3.875rem + 3.375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card4__img-ibg {
    margin-bottom: 3.875rem;
  }
}
@media (min-width: 64.0625em) and (max-width: 90em) {
  @supports (height: clamp( 12.5rem , 4.4728915663rem  +  12.5301204819vw , 15.75rem )) {
    .card4__img-ibg {
      height: clamp( 12.5rem , 4.4728915663rem  +  12.5301204819vw , 15.75rem );
    }
  }
  @supports not (height: clamp( 12.5rem , 4.4728915663rem  +  12.5301204819vw , 15.75rem )) {
    .card4__img-ibg {
      height: calc(12.5rem + 3.25 * (100vw - 64.0625rem) / 25.9375);
    }
  }
}
@media (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 11.125rem , 6.1818181818rem  +  24.7159090909vw , 22rem )) {
    .card4__img-ibg {
      height: clamp( 11.125rem , 6.1818181818rem  +  24.7159090909vw , 22rem );
    }
  }
  @supports not (height: clamp( 11.125rem , 6.1818181818rem  +  24.7159090909vw , 22rem )) {
    .card4__img-ibg {
      height: calc(11.125rem + 10.875 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 20em) {
  .card4__img-ibg {
    height: 11.125rem;
  }
}
.card4__title {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  font-family: Loos ExtraWide;
  color: #fbf0e9;
  text-transform: uppercase;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .card4__title {
      font-size: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (font-size: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .card4__title {
      font-size: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card4__title {
    font-size: 1.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .card4__title {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .card4__title {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card4__title {
    line-height: 1.5rem;
  }
}
.card5 {
  padding: 12px;
  background-color: #e35e59;
  grid-column: 18/25;
  grid-row: 2/4;
}
@media (max-width: 1024px) {
  .card5 {
    width: 49%;
    height: 662px;
  }
}
@media (max-width: 1024px) and (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card5 {
      height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem );
    }
  }
  @supports not (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card5 {
      height: calc(25.5rem + 15.875 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 1024px) and (max-width: 20em) {
  .card5 {
    height: 25.5rem;
  }
}
@media (max-width: 650px) {
  .card5 {
    width: 100%;
  }
}
.card5__img-ibg {
  width: 100%;
  max-width: 100%;
  height: 408px;
  margin-bottom: 24px;
  margin-bottom: 15px;
  padding: 12px;
  background-color: #6b4133;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 17rem , 14.5714285714rem  +  12.1428571429vw , 25.5rem )) {
    .card5__img-ibg {
      height: clamp( 17rem , 14.5714285714rem  +  12.1428571429vw , 25.5rem );
    }
  }
  @supports not (height: clamp( 17rem , 14.5714285714rem  +  12.1428571429vw , 25.5rem )) {
    .card5__img-ibg {
      height: calc(17rem + 8.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card5__img-ibg {
    height: 17rem;
  }
}
.card5__title {
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  font-family: Loos ExtraWide;
  text-align: right;
  color: #e35e59;
  text-transform: uppercase;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.5rem , 1.4285714286rem  +  0.3571428571vw , 1.75rem )) {
    .card5__title {
      font-size: clamp( 1.5rem , 1.4285714286rem  +  0.3571428571vw , 1.75rem );
    }
  }
  @supports not (font-size: clamp( 1.5rem , 1.4285714286rem  +  0.3571428571vw , 1.75rem )) {
    .card5__title {
      font-size: calc(1.5rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card5__title {
    font-size: 1.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.4285714286rem  +  0.3571428571vw , 1.75rem )) {
    .card5__title {
      line-height: clamp( 1.5rem , 1.4285714286rem  +  0.3571428571vw , 1.75rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.4285714286rem  +  0.3571428571vw , 1.75rem )) {
    .card5__title {
      line-height: calc(1.5rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card5__title {
    line-height: 1.5rem;
  }
}
.card5__text {
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  font-family: Loos ExtraWide;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #202020;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .card5__text {
      font-size: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (font-size: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .card5__text {
      font-size: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card5__text {
    font-size: 1.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .card5__text {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .card5__text {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card5__text {
    line-height: 1.5rem;
  }
}
.card6 {
  padding: 12px;
  background-color: #6b4133;
  grid-column: 8/18;
  grid-row: 6/8;
}
@media (max-width: 1024px) {
  .card6 {
    display: flex;
    flex-direction: column;
    width: 49%;
    height: 662px;
  }
}
@media (max-width: 1024px) and (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card6 {
      height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem );
    }
  }
  @supports not (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card6 {
      height: calc(25.5rem + 15.875 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 1024px) and (max-width: 20em) {
  .card6 {
    height: 25.5rem;
  }
}
@media (max-width: 650px) {
  .card6 {
    width: 100%;
  }
}
.card6__img-ibg {
  max-width: 364px;
  height: 248px;
  margin-bottom: 110px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (max-width: clamp( 9.25rem , 5.3928571429rem  +  19.2857142857vw , 22.75rem )) {
    .card6__img-ibg {
      max-width: clamp( 9.25rem , 5.3928571429rem  +  19.2857142857vw , 22.75rem );
    }
  }
  @supports not (max-width: clamp( 9.25rem , 5.3928571429rem  +  19.2857142857vw , 22.75rem )) {
    .card6__img-ibg {
      max-width: calc(9.25rem + 13.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card6__img-ibg {
    max-width: 9.25rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 6.25rem , 3.6071428571rem  +  13.2142857143vw , 15.5rem )) {
    .card6__img-ibg {
      height: clamp( 6.25rem , 3.6071428571rem  +  13.2142857143vw , 15.5rem );
    }
  }
  @supports not (height: clamp( 6.25rem , 3.6071428571rem  +  13.2142857143vw , 15.5rem )) {
    .card6__img-ibg {
      height: calc(6.25rem + 9.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .card6__img-ibg {
    height: 6.25rem;
  }
}
@media (max-width: 1024px) {
  .card6__img-ibg {
    margin-bottom: 0;
  }
}
.card6__number {
  display: block;
  width: 243px;
  margin-bottom: 14px;
  margin-left: auto;
  font-weight: 300;
  font-family: Loos ExtraWide;
  text-align: right;
  color: #fbf0e9;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .card6__number {
    width: 210px;
    margin-top: auto;
  }
}
@media (max-width: 1024px) and (min-width: 20em) and (max-width: 40.625em) {
  @supports (width: clamp( 8.625rem , 4.2613636364rem  +  21.8181818182vw , 13.125rem )) {
    .card6__number {
      width: clamp( 8.625rem , 4.2613636364rem  +  21.8181818182vw , 13.125rem );
    }
  }
  @supports not (width: clamp( 8.625rem , 4.2613636364rem  +  21.8181818182vw , 13.125rem )) {
    .card6__number {
      width: calc(8.625rem + 4.5 * (100vw - 20rem) / 20.625);
    }
  }
}
@media (max-width: 1024px) and (max-width: 20em) {
  .card6__number {
    width: 8.625rem;
  }
}
.card6__number img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card6__title {
  max-width: 410px;
  margin-left: auto;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  font-family: Loos ExtraWide;
  text-align: right;
  color: #fbf0e9;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
}
.card7 {
  padding: 12px;
  background-color: #6b4133;
  grid-column: 18/24;
  grid-row: 4/7;
}
@media (max-width: 1024px) {
  .card7 {
    width: 49%;
    height: 662px;
  }
}
@media (max-width: 1024px) and (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card7 {
      height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem );
    }
  }
  @supports not (height: clamp( 25.5rem , 18.2840909091rem  +  36.0795454545vw , 41.375rem )) {
    .card7 {
      height: calc(25.5rem + 15.875 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 1024px) and (max-width: 20em) {
  .card7 {
    height: 25.5rem;
  }
}
@media (max-width: 650px) {
  .card7 {
    width: 100%;
  }
}
.card7__img-ibg {
  width: 100%;
  height: 252px;
  margin-bottom: 14px;
}
@media (min-width: 64.0625em) and (max-width: 90em) {
  @supports (height: clamp( 9.375rem , -6.3704819277rem  +  24.578313253vw , 15.75rem )) {
    .card7__img-ibg {
      height: clamp( 9.375rem , -6.3704819277rem  +  24.578313253vw , 15.75rem );
    }
  }
  @supports not (height: clamp( 9.375rem , -6.3704819277rem  +  24.578313253vw , 15.75rem )) {
    .card7__img-ibg {
      height: calc(9.375rem + 6.375 * (100vw - 64.0625rem) / 25.9375);
    }
  }
}
@media (min-width: 20em) and (max-width: 64em) {
  @supports (height: clamp( 9.375rem , 5.1136363636rem  +  21.3068181818vw , 18.75rem )) {
    .card7__img-ibg {
      height: clamp( 9.375rem , 5.1136363636rem  +  21.3068181818vw , 18.75rem );
    }
  }
  @supports not (height: clamp( 9.375rem , 5.1136363636rem  +  21.3068181818vw , 18.75rem )) {
    .card7__img-ibg {
      height: calc(9.375rem + 9.375 * (100vw - 20rem) / 44);
    }
  }
}
@media (max-width: 20em) {
  .card7__img-ibg {
    height: 9.375rem;
  }
}
.card7__title, .card7__text {
  max-width: 264px;
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  font-family: Loos ExtraWide;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.card7__title {
  margin-bottom: 25px;
}
.card7__text {
  color: #e7fe59;
}
.instructions {
  margin-top: -41px;
}
@media (max-width: 1200px) {
  .instructions {
    margin-top: -21px;
  }
}
@media (max-width: 767px) {
  .instructions {
    margin-top: -12px;
  }
}
.instructions__list {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(1px, 48px));
}
@media (max-width: 767px) {
  .instructions__list {
    display: flex;
    flex-direction: column;
    row-gap: 36px;
  }
}
.instructions__items:nth-child(3n+1) {
  display: flex;
  align-items: center;
  grid-column: 1/25;
}
@media (max-width: 767px) {
  .instructions__items:nth-child(3n+1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
}
.instructions__items:nth-child(3n+1) .instructions__img-ibg {
  flex-shrink: 0;
  width: 100%;
  max-width: 1008px;
  height: 876px;
  margin-right: 12px;
}
@media (min-width: 48em) and (max-width: 90em) {
  @supports (max-width: clamp( 31rem , -5.5714285714rem  +  76.1904761905vw , 63rem )) {
    .instructions__items:nth-child(3n+1) .instructions__img-ibg {
      max-width: clamp( 31rem , -5.5714285714rem  +  76.1904761905vw , 63rem );
    }
  }
  @supports not (max-width: clamp( 31rem , -5.5714285714rem  +  76.1904761905vw , 63rem )) {
    .instructions__items:nth-child(3n+1) .instructions__img-ibg {
      max-width: calc(31rem + 32 * (100vw - 48rem) / 42);
    }
  }
}
@media (min-width: 20em) and (max-width: 47.9375em) {
  @supports (max-width: clamp( 18.5rem , 9.5514541387rem  +  44.7427293065vw , 31rem )) {
    .instructions__items:nth-child(3n+1) .instructions__img-ibg {
      max-width: clamp( 18.5rem , 9.5514541387rem  +  44.7427293065vw , 31rem );
    }
  }
  @supports not (max-width: clamp( 18.5rem , 9.5514541387rem  +  44.7427293065vw , 31rem )) {
    .instructions__items:nth-child(3n+1) .instructions__img-ibg {
      max-width: calc(18.5rem + 12.5 * (100vw - 20rem) / 27.9375);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+1) .instructions__img-ibg {
    max-width: 18.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 16rem , 4.9285714286rem  +  55.3571428571vw , 54.75rem )) {
    .instructions__items:nth-child(3n+1) .instructions__img-ibg {
      height: clamp( 16rem , 4.9285714286rem  +  55.3571428571vw , 54.75rem );
    }
  }
  @supports not (height: clamp( 16rem , 4.9285714286rem  +  55.3571428571vw , 54.75rem )) {
    .instructions__items:nth-child(3n+1) .instructions__img-ibg {
      height: calc(16rem + 38.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+1) .instructions__img-ibg {
    height: 16rem;
  }
}
.instructions__items:nth-child(3n+1) .instructions__title {
  margin-top: 51px;
  font-size: 60px;
  line-height: 60px;
  font-family: Maregraphe Display;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2.25rem , 1.8214285714rem  +  2.1428571429vw , 3.75rem )) {
    .instructions__items:nth-child(3n+1) .instructions__title {
      font-size: clamp( 2.25rem , 1.8214285714rem  +  2.1428571429vw , 3.75rem );
    }
  }
  @supports not (font-size: clamp( 2.25rem , 1.8214285714rem  +  2.1428571429vw , 3.75rem )) {
    .instructions__items:nth-child(3n+1) .instructions__title {
      font-size: calc(2.25rem + 1.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+1) .instructions__title {
    font-size: 2.25rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2.25rem , 1.8214285714rem  +  2.1428571429vw , 3.75rem )) {
    .instructions__items:nth-child(3n+1) .instructions__title {
      line-height: clamp( 2.25rem , 1.8214285714rem  +  2.1428571429vw , 3.75rem );
    }
  }
  @supports not (line-height: clamp( 2.25rem , 1.8214285714rem  +  2.1428571429vw , 3.75rem )) {
    .instructions__items:nth-child(3n+1) .instructions__title {
      line-height: calc(2.25rem + 1.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+1) .instructions__title {
    line-height: 2.25rem;
  }
}
@media (max-width: 767px) {
  .instructions__items:nth-child(3n+1) .instructions__title {
    margin-top: 20px;
  }
}
.instructions__items:nth-child(3n+2) {
  display: flex;
  flex-direction: column;
  grid-column: 8/18;
}
.instructions__items:nth-child(3n+2) .instructions__img-ibg {
  width: 100%;
  max-width: 588px;
  height: 756px;
  margin-bottom: 12px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (max-width: clamp( 18.5rem , 13.2857142857rem  +  26.0714285714vw , 36.75rem )) {
    .instructions__items:nth-child(3n+2) .instructions__img-ibg {
      max-width: clamp( 18.5rem , 13.2857142857rem  +  26.0714285714vw , 36.75rem );
    }
  }
  @supports not (max-width: clamp( 18.5rem , 13.2857142857rem  +  26.0714285714vw , 36.75rem )) {
    .instructions__items:nth-child(3n+2) .instructions__img-ibg {
      max-width: calc(18.5rem + 18.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+2) .instructions__img-ibg {
    max-width: 18.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 23.8125rem , 17.1160714286rem  +  33.4821428571vw , 47.25rem )) {
    .instructions__items:nth-child(3n+2) .instructions__img-ibg {
      height: clamp( 23.8125rem , 17.1160714286rem  +  33.4821428571vw , 47.25rem );
    }
  }
  @supports not (height: clamp( 23.8125rem , 17.1160714286rem  +  33.4821428571vw , 47.25rem )) {
    .instructions__items:nth-child(3n+2) .instructions__img-ibg {
      height: calc(23.8125rem + 23.4375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+2) .instructions__img-ibg {
    height: 23.8125rem;
  }
}
.instructions__items:nth-child(3n+2) .instructions__title {
  max-width: 526px;
  padding-top: 7px;
  font-weight: 300;
  font-size: 60px;
  line-height: 60px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.05em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .instructions__items:nth-child(3n+2) .instructions__title {
      font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .instructions__items:nth-child(3n+2) .instructions__title {
      font-size: calc(2rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+2) .instructions__title {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .instructions__items:nth-child(3n+2) .instructions__title {
      line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.5rem  +  2.5vw , 3.75rem )) {
    .instructions__items:nth-child(3n+2) .instructions__title {
      line-height: calc(2rem + 1.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+2) .instructions__title {
    line-height: 2rem;
  }
}
@media (max-width: 768px) {
  .instructions__items:nth-child(3n+2) .instructions__title {
    max-width: 100%;
  }
}
.instructions__items:nth-child(3n+3) {
  display: flex;
  flex-direction: column;
  grid-column: 18/25;
}
.instructions__items:nth-child(3n+3) .instructions__img-ibg {
  width: 100%;
  max-width: 408px;
  height: 576px;
  margin-bottom: 12px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (max-width: clamp( 18.5rem , 16.5rem  +  10vw , 25.5rem )) {
    .instructions__items:nth-child(3n+3) .instructions__img-ibg {
      max-width: clamp( 18.5rem , 16.5rem  +  10vw , 25.5rem );
    }
  }
  @supports not (max-width: clamp( 18.5rem , 16.5rem  +  10vw , 25.5rem )) {
    .instructions__items:nth-child(3n+3) .instructions__img-ibg {
      max-width: calc(18.5rem + 7 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+3) .instructions__img-ibg {
    max-width: 18.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 26rem , 23.1428571429rem  +  14.2857142857vw , 36rem )) {
    .instructions__items:nth-child(3n+3) .instructions__img-ibg {
      height: clamp( 26rem , 23.1428571429rem  +  14.2857142857vw , 36rem );
    }
  }
  @supports not (height: clamp( 26rem , 23.1428571429rem  +  14.2857142857vw , 36rem )) {
    .instructions__items:nth-child(3n+3) .instructions__img-ibg {
      height: calc(26rem + 10 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+3) .instructions__img-ibg {
    height: 26rem;
  }
}
.instructions__items:nth-child(3n+3) .instructions__title {
  padding-top: 5px;
  font-size: 44px;
  line-height: 44px;
  font-family: Maregraphe Display;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .instructions__items:nth-child(3n+3) .instructions__title {
      font-size: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem );
    }
  }
  @supports not (font-size: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .instructions__items:nth-child(3n+3) .instructions__title {
      font-size: calc(2.25rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+3) .instructions__title {
    font-size: 2.25rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .instructions__items:nth-child(3n+3) .instructions__title {
      line-height: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem );
    }
  }
  @supports not (line-height: clamp( 2.25rem , 2.1071428571rem  +  0.7142857143vw , 2.75rem )) {
    .instructions__items:nth-child(3n+3) .instructions__title {
      line-height: calc(2.25rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .instructions__items:nth-child(3n+3) .instructions__title {
    line-height: 2.25rem;
  }
}
.instructions__items:hover .instructions__title {
  color: #e35e59;
}
.instructions__title {
  transition: 0.3s;
}
.about__container {
  display: flex;
  align-items: flex-end;
  column-gap: 72px;
}
@media (max-width: 1023px) {
  .about__container {
    flex-direction: column-reverse;
  }
}
.about__content {
  display: flex;
  flex-direction: column;
  row-gap: 23px;
}
@media (max-width: 1400px) {
  .about__content {
    padding-top: 72px;
  }
}
.about__text {
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  font-family: Loos Normal;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .about__text {
      font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem );
    }
  }
  @supports not (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .about__text {
      font-size: calc(1.125rem + 0.1875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .about__text {
    font-size: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .about__text {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .about__text {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .about__text {
    line-height: 1.5rem;
  }
}
.about__email {
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  font-family: Loos Normal;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .about__email {
      font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem );
    }
  }
  @supports not (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .about__email {
      font-size: calc(1.125rem + 0.1875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .about__email {
    font-size: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .about__email {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .about__email {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .about__email {
    line-height: 1.5rem;
  }
}
.about__img-ibg {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  max-width: 948px;
  height: 924px;
}
@media (min-width: 64em) and (max-width: 90em) {
  @supports (max-width: clamp( 44.0625rem , 6.6778846154rem  +  58.4134615385vw , 59.25rem )) {
    .about__img-ibg {
      max-width: clamp( 44.0625rem , 6.6778846154rem  +  58.4134615385vw , 59.25rem );
    }
  }
  @supports not (max-width: clamp( 44.0625rem , 6.6778846154rem  +  58.4134615385vw , 59.25rem )) {
    .about__img-ibg {
      max-width: calc(44.0625rem + 15.1875 * (100vw - 64rem) / 26);
    }
  }
}
@media (min-width: 64em) and (max-width: 90em) {
  @supports (height: clamp( 43.125rem , 7.125rem  +  56.25vw , 57.75rem )) {
    .about__img-ibg {
      height: clamp( 43.125rem , 7.125rem  +  56.25vw , 57.75rem );
    }
  }
  @supports not (height: clamp( 43.125rem , 7.125rem  +  56.25vw , 57.75rem )) {
    .about__img-ibg {
      height: calc(43.125rem + 14.625 * (100vw - 64rem) / 26);
    }
  }
}
@media (min-width: 20em) and (max-width: 63.9375em) {
  @supports (height: clamp( 18.5rem , 7.2908961593rem  +  56.0455192034vw , 43.125rem )) {
    .about__img-ibg {
      height: clamp( 18.5rem , 7.2908961593rem  +  56.0455192034vw , 43.125rem );
    }
  }
  @supports not (height: clamp( 18.5rem , 7.2908961593rem  +  56.0455192034vw , 43.125rem )) {
    .about__img-ibg {
      height: calc(18.5rem + 24.625 * (100vw - 20rem) / 43.9375);
    }
  }
}
@media (max-width: 20em) {
  .about__img-ibg {
    height: 18.5rem;
  }
}
@media (max-width: 1023px) {
  .about__img-ibg {
    max-width: 100%;
    margin-top: 50px;
  }
}
.about__img-author {
  position: absolute;
  right: 0;
  bottom: -14px;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  font-family: Loos Normal;
}
.page__rubrics {
  margin-top: 144px;
  margin-left: 252px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-top: clamp( 6.0625rem , 5.2232142857rem  +  4.1964285714vw , 9rem )) {
    .page__rubrics {
      margin-top: clamp( 6.0625rem , 5.2232142857rem  +  4.1964285714vw , 9rem );
    }
  }
  @supports not (margin-top: clamp( 6.0625rem , 5.2232142857rem  +  4.1964285714vw , 9rem )) {
    .page__rubrics {
      margin-top: calc(6.0625rem + 2.9375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .page__rubrics {
    margin-top: 6.0625rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-left: clamp( 0.00000625rem , -4.4999919643rem  +  22.4999910714vw , 15.75rem )) {
    .page__rubrics {
      margin-left: clamp( 0.00000625rem , -4.4999919643rem  +  22.4999910714vw , 15.75rem );
    }
  }
  @supports not (margin-left: clamp( 0.00000625rem , -4.4999919643rem  +  22.4999910714vw , 15.75rem )) {
    .page__rubrics {
      margin-left: calc(0.00000625rem + 15.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .page__rubrics {
    margin-left: 0.00000625rem;
  }
}
@media (max-width: 767px) {
  .page__rubrics {
    margin-left: 0;
  }
}
.rubrics__title {
  margin-bottom: 24px;
  font-weight: 300;
  font-size: 96px;
  line-height: 96px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.05em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem )) {
    .rubrics__title {
      font-size: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem )) {
    .rubrics__title {
      font-size: calc(2rem + 4 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .rubrics__title {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem )) {
    .rubrics__title {
      line-height: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem )) {
    .rubrics__title {
      line-height: calc(2rem + 4 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .rubrics__title {
    line-height: 2rem;
  }
}
.rubrics__filters {
  margin-bottom: 72px;
}
.rubrics__list {
  display: flex;
  flex-direction: column;
  row-gap: 72px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (row-gap: clamp( 2.1875rem , 1.5267857143rem  +  3.3035714286vw , 4.5rem )) {
    .rubrics__list {
      row-gap: clamp( 2.1875rem , 1.5267857143rem  +  3.3035714286vw , 4.5rem );
    }
  }
  @supports not (row-gap: clamp( 2.1875rem , 1.5267857143rem  +  3.3035714286vw , 4.5rem )) {
    .rubrics__list {
      row-gap: calc(2.1875rem + 2.3125 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .rubrics__list {
    row-gap: 2.1875rem;
  }
}
.rubrics__link {
  display: flex;
  gap: 12px;
}
@media (max-width: 1279px) {
  .rubrics__link {
    flex-direction: column;
  }
}
.rubrics__link:hover .rubrics__item-title,
.rubrics__link:hover .rubrics__item-text {
  color: #e35e59;
}
.rubrics__item-ibg {
  flex-shrink: 0;
  width: 408px;
  height: 264px;
}
@media (min-width: 48em) and (max-width: 90em) {
  @supports (width: clamp( 21.25rem , 16.3928571429rem  +  10.119047619vw , 25.5rem )) {
    .rubrics__item-ibg {
      width: clamp( 21.25rem , 16.3928571429rem  +  10.119047619vw , 25.5rem );
    }
  }
  @supports not (width: clamp( 21.25rem , 16.3928571429rem  +  10.119047619vw , 25.5rem )) {
    .rubrics__item-ibg {
      width: calc(21.25rem + 4.25 * (100vw - 48rem) / 42);
    }
  }
}
@media (min-width: 48em) and (max-width: 90em) {
  @supports (height: clamp( 13.75rem , 10.6071428571rem  +  6.5476190476vw , 16.5rem )) {
    .rubrics__item-ibg {
      height: clamp( 13.75rem , 10.6071428571rem  +  6.5476190476vw , 16.5rem );
    }
  }
  @supports not (height: clamp( 13.75rem , 10.6071428571rem  +  6.5476190476vw , 16.5rem )) {
    .rubrics__item-ibg {
      height: calc(13.75rem + 2.75 * (100vw - 48rem) / 42);
    }
  }
}
@media (min-width: 20em) and (max-width: 47.9375em) {
  @supports (height: clamp( 12rem , 2.6935123043rem  +  46.5324384787vw , 25rem )) {
    .rubrics__item-ibg {
      height: clamp( 12rem , 2.6935123043rem  +  46.5324384787vw , 25rem );
    }
  }
  @supports not (height: clamp( 12rem , 2.6935123043rem  +  46.5324384787vw , 25rem )) {
    .rubrics__item-ibg {
      height: calc(12rem + 13 * (100vw - 20rem) / 27.9375);
    }
  }
}
@media (max-width: 20em) {
  .rubrics__item-ibg {
    height: 12rem;
  }
}
@media (max-width: 767px) {
  .rubrics__item-ibg {
    width: 100%;
  }
}
.rubrics__content {
  display: flex;
  flex-direction: column;
  max-width: 746px;
  padding-top: 5px;
  gap: 10px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (gap: clamp( 0.125rem , -0.0178571429rem  +  0.7142857143vw , 0.625rem )) {
    .rubrics__content {
      gap: clamp( 0.125rem , -0.0178571429rem  +  0.7142857143vw , 0.625rem );
    }
  }
  @supports not (gap: clamp( 0.125rem , -0.0178571429rem  +  0.7142857143vw , 0.625rem )) {
    .rubrics__content {
      gap: calc(0.125rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .rubrics__content {
    gap: 0.125rem;
  }
}
.rubrics__item-title {
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.03em;
  transition: 0.3s;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .rubrics__item-title {
      font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .rubrics__item-title {
      font-size: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .rubrics__item-title {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .rubrics__item-title {
      line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .rubrics__item-title {
      line-height: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .rubrics__item-title {
    line-height: 2rem;
  }
}
.rubrics__item-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  transition: 0.3s;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .rubrics__item-text {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .rubrics__item-text {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .rubrics__item-text {
    font-size: 0.875rem;
  }
}
.page__search {
  margin-top: 131px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-top: clamp( 4.5rem , 3.4464285714rem  +  5.2678571429vw , 8.1875rem )) {
    .page__search {
      margin-top: clamp( 4.5rem , 3.4464285714rem  +  5.2678571429vw , 8.1875rem );
    }
  }
  @supports not (margin-top: clamp( 4.5rem , 3.4464285714rem  +  5.2678571429vw , 8.1875rem )) {
    .page__search {
      margin-top: calc(4.5rem + 3.6875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .page__search {
    margin-top: 4.5rem;
  }
}
@media (max-width: 768px) {
  .search {
    display: flex;
    flex-direction: column;
  }
}
.search__container {
  max-width: 828px;
}
@media (max-width: 768px) {
  .search__container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}
.search__form {
  position: relative;
  max-width: 828px;
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .search__form {
    width: 100%;
  }
}
.search__input {
  max-width: 828px;
  width: 100%;
  height: 72px;
  padding: 25px 200px 25px 48px;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  background: #ffffff;
  border-radius: 36px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-top: clamp( 1.125rem , 1rem  +  0.625vw , 1.5625rem )) {
    .search__input {
      padding-top: clamp( 1.125rem , 1rem  +  0.625vw , 1.5625rem );
    }
  }
  @supports not (padding-top: clamp( 1.125rem , 1rem  +  0.625vw , 1.5625rem )) {
    .search__input {
      padding-top: calc(1.125rem + 0.4375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .search__input {
    padding-top: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 1.125rem , 1rem  +  0.625vw , 1.5625rem )) {
    .search__input {
      padding-bottom: clamp( 1.125rem , 1rem  +  0.625vw , 1.5625rem );
    }
  }
  @supports not (padding-bottom: clamp( 1.125rem , 1rem  +  0.625vw , 1.5625rem )) {
    .search__input {
      padding-bottom: calc(1.125rem + 0.4375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .search__input {
    padding-bottom: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-left: clamp( 2.25rem , 2.0357142857rem  +  1.0714285714vw , 3rem )) {
    .search__input {
      padding-left: clamp( 2.25rem , 2.0357142857rem  +  1.0714285714vw , 3rem );
    }
  }
  @supports not (padding-left: clamp( 2.25rem , 2.0357142857rem  +  1.0714285714vw , 3rem )) {
    .search__input {
      padding-left: calc(2.25rem + 0.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .search__input {
    padding-left: 2.25rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 3.5rem , 3.2142857143rem  +  1.4285714286vw , 4.5rem )) {
    .search__input {
      height: clamp( 3.5rem , 3.2142857143rem  +  1.4285714286vw , 4.5rem );
    }
  }
  @supports not (height: clamp( 3.5rem , 3.2142857143rem  +  1.4285714286vw , 4.5rem )) {
    .search__input {
      height: calc(3.5rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .search__input {
    height: 3.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .search__input {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .search__input {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .search__input {
    font-size: 0.875rem;
  }
}
@media (max-width: 1024px) {
  .search__input {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .search__input {
    padding-right: 50px;
  }
}
.search__btn {
  position: absolute;
  top: 25px;
  right: 175px;
  width: 24px;
  height: 24px;
  background: url("/img/icons/close-black.svg") center/cover no-repeat;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (top: clamp( 0.9375rem , 0.7589285714rem  +  0.8928571429vw , 1.5625rem )) {
    .search__btn {
      top: clamp( 0.9375rem , 0.7589285714rem  +  0.8928571429vw , 1.5625rem );
    }
  }
  @supports not (top: clamp( 0.9375rem , 0.7589285714rem  +  0.8928571429vw , 1.5625rem )) {
    .search__btn {
      top: calc(0.9375rem + 0.625 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .search__btn {
    top: 0.9375rem;
  }
}
@media (max-width: 768px) {
  .search__btn {
    right: 24px;
  }
}
@media (max-width: 768px) {
  .search .menu-search__btn {
    margin-top: 10px;
  }
}
.search__filters {
  margin-bottom: 76px;
}
.search__list {
  display: flex;
  flex-direction: column;
  gap: 72px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (gap: clamp( 2rem , 1.2857142857rem  +  3.5714285714vw , 4.5rem )) {
    .search__list {
      gap: clamp( 2rem , 1.2857142857rem  +  3.5714285714vw , 4.5rem );
    }
  }
  @supports not (gap: clamp( 2rem , 1.2857142857rem  +  3.5714285714vw , 4.5rem )) {
    .search__list {
      gap: calc(2rem + 2.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .search__list {
    gap: 2rem;
  }
}
.search__link {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.search__link:hover .search__tag,
.search__link:hover .search__title,
.search__link:hover .search__text {
  color: #e35e59;
}
.search__tag {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  transition: 0.3s;
}
.search__title {
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.03em;
  transition: 0.3s;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.625rem , 1.2321428571rem  +  1.9642857143vw , 3rem )) {
    .search__title {
      font-size: clamp( 1.625rem , 1.2321428571rem  +  1.9642857143vw , 3rem );
    }
  }
  @supports not (font-size: clamp( 1.625rem , 1.2321428571rem  +  1.9642857143vw , 3rem )) {
    .search__title {
      font-size: calc(1.625rem + 1.375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .search__title {
    font-size: 1.625rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.625rem , 1.2321428571rem  +  1.9642857143vw , 3rem )) {
    .search__title {
      line-height: clamp( 1.625rem , 1.2321428571rem  +  1.9642857143vw , 3rem );
    }
  }
  @supports not (line-height: clamp( 1.625rem , 1.2321428571rem  +  1.9642857143vw , 3rem )) {
    .search__title {
      line-height: calc(1.625rem + 1.375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .search__title {
    line-height: 1.625rem;
  }
}
.search__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  transition: 0.3s;
}
@media (max-width: 768px) {
  .search .filters {
    width: unset !important;
  }
}
.news-post:first-child .news-post__header {
  margin-top: -12px;
  padding-top: 228px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-top: clamp( 8.375rem , 6.6964285714rem  +  8.3928571429vw , 14.25rem )) {
    .news-post:first-child .news-post__header {
      padding-top: clamp( 8.375rem , 6.6964285714rem  +  8.3928571429vw , 14.25rem );
    }
  }
  @supports not (padding-top: clamp( 8.375rem , 6.6964285714rem  +  8.3928571429vw , 14.25rem )) {
    .news-post:first-child .news-post__header {
      padding-top: calc(8.375rem + 5.875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post:first-child .news-post__header {
    padding-top: 8.375rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem )) {
    .news-post:first-child .news-post__header {
      padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem );
    }
  }
  @supports not (padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem )) {
    .news-post:first-child .news-post__header {
      padding-bottom: calc(0.5rem + -0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post:first-child .news-post__header {
    padding-bottom: 0.5rem;
  }
}
.news-post:nth-child(3n+1) .news-post__header {
  background-color: #f4d4fc;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem )) {
    .news-post:nth-child(3n+1) .news-post__header {
      padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem );
    }
  }
  @supports not (padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem )) {
    .news-post:nth-child(3n+1) .news-post__header {
      padding-bottom: calc(0.5rem + -0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post:nth-child(3n+1) .news-post__header {
    padding-bottom: 0.5rem;
  }
}
.news-post:nth-child(3n+2) .news-post__header {
  background-color: #eab271;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem )) {
    .news-post:nth-child(3n+2) .news-post__header {
      padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem );
    }
  }
  @supports not (padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem )) {
    .news-post:nth-child(3n+2) .news-post__header {
      padding-bottom: calc(0.5rem + -0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post:nth-child(3n+2) .news-post__header {
    padding-bottom: 0.5rem;
  }
}
.news-post:nth-child(3n+3) .news-post__header {
  background-color: #575ab5;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem )) {
    .news-post:nth-child(3n+3) .news-post__header {
      padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem );
    }
  }
  @supports not (padding-bottom: clamp( 0.00000625rem , 0.6428553571rem  +  -0.7142767857vw , 0.5rem )) {
    .news-post:nth-child(3n+3) .news-post__header {
      padding-bottom: calc(0.5rem + -0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post:nth-child(3n+3) .news-post__header {
    padding-bottom: 0.5rem;
  }
}
.news-post:nth-child(3n+3) .news-post__tag {
  color: #ffffff;
}
.news-post:nth-child(3n+3) .news-post__data {
  color: #ffffff;
}
.news-post:nth-child(3n+3) .news-post__title {
  color: #ffffff;
}
.news-post__header {
  padding-top: 134px;
}
.news-post__header-content {
  display: grid;
  gap: 12px 12px;
  grid-template-columns: repeat(auto-fill, minmax(0, 48px));
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (row-gap: clamp( 0.5rem , 0.4285714286rem  +  0.3571428571vw , 0.75rem )) {
    .news-post__header-content {
      row-gap: clamp( 0.5rem , 0.4285714286rem  +  0.3571428571vw , 0.75rem );
    }
  }
  @supports not (row-gap: clamp( 0.5rem , 0.4285714286rem  +  0.3571428571vw , 0.75rem )) {
    .news-post__header-content {
      row-gap: calc(0.5rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post__header-content {
    row-gap: 0.5rem;
  }
}
.news-post__info {
  display: flex;
  align-items: flex-end;
  column-gap: 24px;
  grid-column: 5/9;
}
@media (max-width: 768px) {
  .news-post__info {
    grid-column: 1/25;
  }
}
.news-post__tag {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .news-post__tag {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .news-post__tag {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post__tag {
    font-size: 0.875rem;
  }
}
.news-post__data {
  font-size: 14px;
  line-height: 1;
  font-family: Loos Normal;
}
.news-post__title {
  font-size: 96px;
  line-height: 96px;
  font-family: Maregraphe Display;
  letter-spacing: -0.03em;
  margin-bottom: 12px;
  grid-column: 5/19;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2.25rem , 1.1785714286rem  +  5.3571428571vw , 6rem )) {
    .news-post__title {
      font-size: clamp( 2.25rem , 1.1785714286rem  +  5.3571428571vw , 6rem );
    }
  }
  @supports not (font-size: clamp( 2.25rem , 1.1785714286rem  +  5.3571428571vw , 6rem )) {
    .news-post__title {
      font-size: calc(2.25rem + 3.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post__title {
    font-size: 2.25rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2.25rem , 1.1785714286rem  +  5.3571428571vw , 6rem )) {
    .news-post__title {
      line-height: clamp( 2.25rem , 1.1785714286rem  +  5.3571428571vw , 6rem );
    }
  }
  @supports not (line-height: clamp( 2.25rem , 1.1785714286rem  +  5.3571428571vw , 6rem )) {
    .news-post__title {
      line-height: calc(2.25rem + 3.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post__title {
    line-height: 2.25rem;
  }
}
@media (max-width: 768px) {
  .news-post__title {
    grid-column: 1/25;
  }
}
@media (max-width: 425px) {
  .news-post__title {
    max-width: 284px;
  }
}
.news-post__body-content {
  display: grid;
  padding-bottom: 144px;
  gap: 36px 12px;
  grid-template-columns: repeat(auto-fill, minmax(0, 48px));
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (padding-bottom: clamp( 4.5rem , 3.2142857143rem  +  6.4285714286vw , 9rem )) {
    .news-post__body-content {
      padding-bottom: clamp( 4.5rem , 3.2142857143rem  +  6.4285714286vw , 9rem );
    }
  }
  @supports not (padding-bottom: clamp( 4.5rem , 3.2142857143rem  +  6.4285714286vw , 9rem )) {
    .news-post__body-content {
      padding-bottom: calc(4.5rem + 4.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post__body-content {
    padding-bottom: 4.5rem;
  }
}
@media (max-width: 768px) {
  .news-post__body-content {
    row-gap: 24px;
  }
}
.news-post__text {
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  font-family: Loos Normal;
  grid-column: 11/25;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .news-post__text {
      font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem );
    }
  }
  @supports not (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .news-post__text {
      font-size: calc(1.125rem + 0.1875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post__text {
    font-size: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .news-post__text {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .news-post__text {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post__text {
    line-height: 1.5rem;
  }
}
@media (max-width: 768px) {
  .news-post__text {
    grid-column: 1/25;
  }
}
.news-post__link {
  position: relative;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  font-family: Loos Normal;
  transition: 0.3s;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .news-post__link {
      font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem );
    }
  }
  @supports not (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .news-post__link {
      font-size: calc(1.125rem + 0.1875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post__link {
    font-size: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .news-post__link {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .news-post__link {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .news-post__link {
    line-height: 1.5rem;
  }
}
.news-post__link:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #202020;
}
.news-post__link:hover {
  color: #e35e59;
}
.article__text-title {
  max-width: 530px;
  margin-bottom: 36px;
  font-weight: 400;
  margin-top: -12px;
  font-size: 32px;
  line-height: 32px;
  font-family: Loos ExtraWide;
  text-transform: uppercase;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .article__text-title {
      font-size: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (font-size: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .article__text-title {
      font-size: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__text-title {
    font-size: 1.5rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .article__text-title {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .article__text-title {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__text-title {
    line-height: 1.5rem;
  }
}
@media (max-width: 768px) {
  .article__text-title {
    margin-bottom: 13px;
  }
}
.article__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100vh;
  margin-top: -13px;
  background-color: #575ab5;
}
.article__header-top {
  padding-top: 12px;
}
@media (max-width: 768px) {
  .article__header-top {
    position: relative;
    height: 50vh;
    background-color: #575ab5;
  }
}
@media (max-width: 768px) {
  .article__header-top .article__container {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px 12px 12px 12px;
  }
}
@media (max-width: 768px) {
  .article__header-bottom .article__container {
    display: flex;
    justify-content: flex-end;
  }
}
@media (max-width: 768px) {
  .article__header-bottom {
    height: 50vh;
    background-color: #fbf0e9;
  }
}
.article__container {
  position: relative;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(6px, 48px));
}
@media (max-width: 1024px) {
  .article__container {
    grid-template-columns: repeat(auto-fill, minmax(6px, 48px));
  }
}
@media (max-width: 768px) {
  .article__container {
    display: flex;
    flex-direction: column;
  }
}
.article__header-content {
  position: relative;
  grid-column: 5/19;
}
@media (max-width: 768px) {
  .article__header-content {
    margin-bottom: 0;
  }
}
@media (max-height: 900px) {
  .article__header-content {
    grid-column: 5/23;
  }
}
@media (min-height: 901px) and (max-height: 1220px) and (min-width: 1440px) {
  .article__header-content {
    grid-column: 5/23;
  }
}
.article__header-ibg {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 528px;
  height: 328px;
  margin-top: 13px;
  margin-bottom: 137px;
}
.article__header-ibg img {
  position: relative;
 }
@media (min-width: 20em) and (max-width: 90em) {
  @supports (width: clamp( 12.0625rem , 6.0803571429rem  +  29.9107142857vw , 33rem )) {
    .article__header-ibg {
      width: clamp( 12.0625rem , 6.0803571429rem  +  29.9107142857vw , 33rem );
    }
  }
  @supports not (width: clamp( 12.0625rem , 6.0803571429rem  +  29.9107142857vw , 33rem )) {
    .article__header-ibg {
      width: calc(12.0625rem + 20.9375 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__header-ibg {
    width: 12.0625rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 7.5rem , 3.7857142857rem  +  18.5714285714vw , 20.5rem )) {
    .article__header-ibg {
      height: clamp( 7.5rem , 3.7857142857rem  +  18.5714285714vw , 20.5rem );
    }
  }
  @supports not (height: clamp( 7.5rem , 3.7857142857rem  +  18.5714285714vw , 20.5rem )) {
    .article__header-ibg {
      height: calc(7.5rem + 13 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__header-ibg {
    height: 7.5rem;
  }
}
@media (min-width: 48.0625em) and (max-width: 90em) {
  @supports (margin-bottom: clamp( 4.1875rem , -1.7576378539rem  +  12.3695976155vw , 9.375rem )) {
    .article__header-ibg {
      margin-bottom: clamp( 4.1875rem , -1.7576378539rem  +  12.3695976155vw , 9.375rem );
    }
  }
  @supports not (margin-bottom: clamp( 4.1875rem , -1.7576378539rem  +  12.3695976155vw , 9.375rem )) {
    .article__header-ibg {
      margin-bottom: calc(4.1875rem + 5.1875 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (max-height: 900px) and (min-width: 48.0625em) and (max-width: 90em) {
  @supports (margin-bottom: clamp( 2.8125rem , 2.4543591654rem  +  0.7451564829vw , 3.125rem )) {
    .article__header-ibg {
      margin-bottom: clamp( 2.8125rem , 2.4543591654rem  +  0.7451564829vw , 3.125rem );
    }
  }
  @supports not (margin-bottom: clamp( 2.8125rem , 2.4543591654rem  +  0.7451564829vw , 3.125rem )) {
    .article__header-ibg {
      margin-bottom: calc(2.8125rem + 0.3125 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (max-width: 768px) {
  .article__header-ibg {
    margin-bottom: 24px !important;
  }
}
.article__header-author {
  position: relative;
  margin-left: auto;
  left: 0;
  bottom: -4px;
  font-size: 11px;
  line-height: 12px;
  font-family: Loos Normal;
  text-align: right;
}
@media (max-width: 768px) {
  .article__header-author {
    top: -17px;
    left: 0;
    margin-right: 12px;
  }
}
.article__info {
  display: flex;
  align-items: flex-end;
  margin-bottom: 18px;
  column-gap: 24px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .article__info {
    padding-top: 0;
    margin-bottom: 10px;
  }
}
.article__tag {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  color: #ffffff;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .article__tag {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .article__tag {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__tag {
    font-size: 0.875rem;
  }
}
@media (max-width: 768px) {
  .article__tag {
    color: #202020;
  }
}
.article__data {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: Loos Normal;
  color: #ffffff;
}
@media (max-width: 768px) {
  .article__data {
    color: #202020;
  }
}
.article__title {
  font-size: 96px;
  line-height: 1;
  font-family: Maregraphe Display;
  color: #ffffff;
  letter-spacing: -0.03em;
  margin-bottom: 20px;
  max-height: 500px;
  overflow: hidden;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2.25rem , 1.1785714286rem  +  5.3571428571vw , 6rem )) {
    .article__title {
      font-size: clamp( 2.25rem , 1.1785714286rem  +  5.3571428571vw , 6rem );
    }
  }
  @supports not (font-size: clamp( 2.25rem , 1.1785714286rem  +  5.3571428571vw , 6rem )) {
    .article__title {
      font-size: calc(2.25rem + 3.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__title {
    font-size: 2.25rem;
  }
}
/* @media (min-width: 48.0625em) and (max-width: 90em) {
  @supports (max-height: clamp( 10.3125rem , -13.6829359165rem  +  49.9254843517vw , 31.25rem )) {
    .article__title {
      max-height: clamp( 10.3125rem , -13.6829359165rem  +  49.9254843517vw , 31.25rem );
    }
  }
  @supports not (max-height: clamp( 10.3125rem , -13.6829359165rem  +  49.9254843517vw , 31.25rem )) {
    .article__title {
      max-height: calc(10.3125rem + 20.9375 * (100vw - 48.0625rem) / 41.9375);
    }
  }
} */
@media (max-height: 900px) and (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2.25rem , 1.7142857143rem  +  2.6785714286vw , 4.125rem )) {
    .article__title {
      font-size: clamp( 2.25rem , 1.7142857143rem  +  2.6785714286vw , 4.125rem );
    }
  }
  @supports not (font-size: clamp( 2.25rem , 1.7142857143rem  +  2.6785714286vw , 4.125rem )) {
    .article__title {
      font-size: calc(2.25rem + 1.875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-height: 900px) and (max-width: 20em) {
  .article__title {
    font-size: 2.25rem;
  }
}
@media (max-width: 768px) {
  .article__title {
    color: #202020;
    margin-bottom: 20px;
    max-height: auto;
  }
}
.article__authors {
  position: absolute;
  top: 13px;
  left: 12px;
}
@media (max-width: 768px) {
  .article__authors {
    position: static;
  }
}
.article__body {
  padding-top: 76px;
}
.article__body-content {
  display: flex;
  flex-direction: column;
  grid-column: 11/25;
}
.article__text {
  margin-bottom: 36px;
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  font-family: Loos Normal;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .article__text {
      font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem );
    }
  }
  @supports not (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .article__text {
      font-size: calc(1.125rem + 0.1875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__text {
    font-size: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .article__text {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .article__text {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__text {
    line-height: 1.5rem;
  }
}
@media (max-width: 768px) {
  .article__text {
    margin-bottom: 24px;
  }
}
.article__text--mb72 {
  margin-bottom: 69px;
}
@media (max-width: 768px) {
  .article__text--mb72 {
    margin-bottom: 46px;
  }
}
.article__text--bold {
  font-weight: 500;
}
.article__text--main {
  margin-bottom: 72px;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  font-family: Loos ExtraWide;
  letter-spacing: -0.03em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.125rem , 0.875rem  +  1.25vw , 2rem )) {
    .article__text--main {
      font-size: clamp( 1.125rem , 0.875rem  +  1.25vw , 2rem );
    }
  }
  @supports not (font-size: clamp( 1.125rem , 0.875rem  +  1.25vw , 2rem )) {
    .article__text--main {
      font-size: calc(1.125rem + 0.875 * (100vw - 20rem) / 70);
    }
  }
}
@media (min-width: 768px) and (max-width: 1451px) {
  .article__text--main {
    margin-left: 9px
  }
}
@media (max-width: 20em) {
  .article__text--main {
    font-size: 1.125rem;
  }
}
@media (min-width: 1452px) and (max-width: 1468px) {
  .article__text--main {
    /* margin-left: 68px */
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.2142857143rem  +  1.4285714286vw , 2.5rem )) {
    .article__text--main {
      line-height: clamp( 1.5rem , 1.2142857143rem  +  1.4285714286vw , 2.5rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.2142857143rem  +  1.4285714286vw , 2.5rem )) {
    .article__text--main {
      line-height: calc(1.5rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__text--main {
    line-height: 1.5rem;
  }
}
@media (max-width: 768px) {
  .article__text--main {
    letter-spacing: normal;
  }
}
.article__link {
  position: relative;
  display: inline;
  padding-bottom: 3px;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  font-family: Loos Normal;
  transition: 0.3s;
  background: linear-gradient(#202020, #202020) no-repeat;
  background-size: 100% 1px;
  background-position: 0 100%;
  text-shadow: 0.05em 0 white, -0.05em 0 white;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .article__link {
      font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem );
    }
  }
  @supports not (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .article__link {
      font-size: calc(1.125rem + 0.1875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__link {
    font-size: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .article__link {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .article__link {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__link {
    line-height: 1.5rem;
  }
}
.article__link:hover {
  color: #e35e59;
  background: linear-gradient(#E35E59, #E35E59) no-repeat;
  background-size: 100% 1px;
  background-position: 0 100%;
  text-shadow: 0.05em 0 white, -0.05em 0 white;
}
.article__link:hover:before {
  background: #e35e59;
}
.article__blockquote {
  margin-bottom: 72px;
}
@media (max-width: 768px) {
  .article__blockquote {
    margin-bottom: 51px;
  }
}
.article__note {
  margin-bottom: 72px;
}
@media (max-width: 768px) {
  .article__note {
    margin-bottom: 39px;
  }
}
.article__body-content--full {
  grid-column: 1/25;
}
.article__img-wrap {
  display: grid;
  margin-bottom: 60px;
  gap: 14px 12px;
  grid-template-columns: repeat(auto-fill, minmax(1px, 48px));
}
@media (max-width: 768px) {
  .article__img-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
    row-gap: 8px;
  }
}
.article__text-ibg {
  width: 100%;
  max-width: 1439px;
  height: 953px;
  grid-column: 1/25;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 12.3125rem , -1.1875rem  +  67.5vw , 59.5625rem )) {
    .article__text-ibg {
      height: clamp( 12.3125rem , -1.1875rem  +  67.5vw , 59.5625rem );
    }
  }
  @supports not (height: clamp( 12.3125rem , -1.1875rem  +  67.5vw , 59.5625rem )) {
    .article__text-ibg {
      height: calc(12.3125rem + 47.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__text-ibg {
    height: 12.3125rem;
  }
}
.article__text-ibg--small {
  width: 100%;
  max-width: 827px;
  height: 551px;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (height: clamp( 12.3125rem , 5.9910714286rem  +  31.6071428571vw , 34.4375rem )) {
    .article__text-ibg--small {
      height: clamp( 12.3125rem , 5.9910714286rem  +  31.6071428571vw , 34.4375rem );
    }
  }
  @supports not (height: clamp( 12.3125rem , 5.9910714286rem  +  31.6071428571vw , 34.4375rem )) {
    .article__text-ibg--small {
      height: calc(12.3125rem + 22.125 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__text-ibg--small {
    height: 12.3125rem;
  }
}
.article__img-desc {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  grid-column: 11/18;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .article__img-desc {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .article__img-desc {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .article__img-desc {
    font-size: 0.875rem;
  }
}
@media (max-width: 425px) {
  .article__img-desc {
    max-width: 186px;
  }
}
.article__img-desc--small {
  grid-column: 1/7;
}
.article__img-author {
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  font-family: Loos Normal;
  grid-column: 20/25;
  justify-self: end;
}
@media (max-width: 768px) {
  .article__img-author {
    order: -1;
  }
}
@media (max-width: 1090px) {
  ._article .article__header-ibg {
    margin-top: auto;
    margin-left: auto;
  }
}
._article-variety .article__header {
  justify-content: flex-start;
  min-height: auto;
  background-color: #fbf0e9;
}
@media (max-width: 768px) {
  ._article-variety .article__header {
    height: max-content;
    min-height: max-content;
  }
}
._article-variety .article__header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  ._article-variety .article__header-wrap {
    flex-direction: column-reverse;
    align-items: stretch;
  }
}
._article-variety .article__header-col {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  ._article-variety .article__header-col {
    margin-top: -12px;
    margin-right: -12px;
    margin-left: -12px;
  }
}
._article-variety .article__header-col:nth-child(1) {
  margin-right: 20px;
}
._article-variety .article__header-col:nth-child(1) {
  height: 876px;
}
@media (min-width: 64em) and (max-width: 90em) {
  @supports (height: clamp( 37.5rem , -4.9615384615rem  +  66.3461538462vw , 54.75rem )) {
    ._article-variety .article__header-col:nth-child(1) {
      height: clamp( 37.5rem , -4.9615384615rem  +  66.3461538462vw , 54.75rem );
    }
  }
  @supports not (height: clamp( 37.5rem , -4.9615384615rem  +  66.3461538462vw , 54.75rem )) {
    ._article-variety .article__header-col:nth-child(1) {
      height: calc(37.5rem + 17.25 * (100vw - 64rem) / 26);
    }
  }
}
@media (min-width: 48.0625em) and (max-width: 63.9375em) {
  @supports (height: clamp( 25rem , -12.844488189rem  +  78.7401574803vw , 37.5rem )) {
    ._article-variety .article__header-col:nth-child(1) {
      height: clamp( 25rem , -12.844488189rem  +  78.7401574803vw , 37.5rem );
    }
  }
  @supports not (height: clamp( 25rem , -12.844488189rem  +  78.7401574803vw , 37.5rem )) {
    ._article-variety .article__header-col:nth-child(1) {
      height: calc(25rem + 12.5 * (100vw - 48.0625rem) / 15.875);
    }
  }
}
@media (min-width: 20em) and (max-width: 48em) {
  @supports (height: clamp( 18.5rem , 13.8571428571rem  +  23.2142857143vw , 25rem )) {
    ._article-variety .article__header-col:nth-child(1) {
      height: clamp( 18.5rem , 13.8571428571rem  +  23.2142857143vw , 25rem );
    }
  }
  @supports not (height: clamp( 18.5rem , 13.8571428571rem  +  23.2142857143vw , 25rem )) {
    ._article-variety .article__header-col:nth-child(1) {
      height: calc(18.5rem + 6.5 * (100vw - 20rem) / 28);
    }
  }
}
@media (max-width: 20em) {
  ._article-variety .article__header-col:nth-child(1) {
    height: 18.5rem;
  }
}
@media (max-height: 900px) and (min-width: 64em) and (max-width: 90em) {
  @supports (height: clamp( 40rem , 38.4615384615rem  +  2.4038461538vw , 40.625rem )) {
    ._article-variety .article__header-col:nth-child(1) {
      height: clamp( 40rem , 38.4615384615rem  +  2.4038461538vw , 40.625rem );
    }
  }
  @supports not (height: clamp( 40rem , 38.4615384615rem  +  2.4038461538vw , 40.625rem )) {
    ._article-variety .article__header-col:nth-child(1) {
      height: calc(40rem + 0.625 * (100vw - 64rem) / 26);
    }
  }
}
@media (max-width: 768px) {
  ._article-variety .article__header-col:nth-child(1) {
    height: auto;
    margin-right: 0;
    margin-left: 0;
  }
}
._article-variety .article .__container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1451px;
}
._article-variety .article__header-ibg {
  position: relative;
  top: 0;
  right: 0;
  flex-shrink: 0;
  width: 948px;
  height: 876px;
  margin-bottom: 0;
  mix-blend-mode: multiply;
}
@media (min-width: 64em) and (max-width: 90em) {
  @supports (width: clamp( 37.5rem , -16.0384615385rem  +  83.6538461538vw , 59.25rem )) {
    ._article-variety .article__header-ibg {
      width: clamp( 37.5rem , -16.0384615385rem  +  83.6538461538vw , 59.25rem );
    }
  }
  @supports not (width: clamp( 37.5rem , -16.0384615385rem  +  83.6538461538vw , 59.25rem )) {
    ._article-variety .article__header-ibg {
      width: calc(37.5rem + 21.75 * (100vw - 64rem) / 26);
    }
  }
}
@media (min-width: 64em) and (max-width: 90em) {
  @supports (height: clamp( 37.5rem , -4.9615384615rem  +  66.3461538462vw , 54.75rem )) {
    ._article-variety .article__header-ibg {
      height: clamp( 37.5rem , -4.9615384615rem  +  66.3461538462vw , 54.75rem );
    }
  }
  @supports not (height: clamp( 37.5rem , -4.9615384615rem  +  66.3461538462vw , 54.75rem )) {
    ._article-variety .article__header-ibg {
      height: calc(37.5rem + 17.25 * (100vw - 64rem) / 26);
    }
  }
}
@media (min-width: 48.0625em) and (max-width: 63.9375em) {
  @supports (width: clamp( 25rem , -12.844488189rem  +  78.7401574803vw , 37.5rem )) {
    ._article-variety .article__header-ibg {
      width: clamp( 25rem , -12.844488189rem  +  78.7401574803vw , 37.5rem );
    }
  }
  @supports not (width: clamp( 25rem , -12.844488189rem  +  78.7401574803vw , 37.5rem )) {
    ._article-variety .article__header-ibg {
      width: calc(25rem + 12.5 * (100vw - 48.0625rem) / 15.875);
    }
  }
}
@media (min-width: 48.0625em) and (max-width: 63.9375em) {
  @supports (height: clamp( 25rem , -12.844488189rem  +  78.7401574803vw , 37.5rem )) {
    ._article-variety .article__header-ibg {
      height: clamp( 25rem , -12.844488189rem  +  78.7401574803vw , 37.5rem );
    }
  }
  @supports not (height: clamp( 25rem , -12.844488189rem  +  78.7401574803vw , 37.5rem )) {
    ._article-variety .article__header-ibg {
      height: calc(25rem + 12.5 * (100vw - 48.0625rem) / 15.875);
    }
  }
}
@media (min-width: 20em) and (max-width: 48em) {
  @supports (height: clamp( 18.5rem , 13.8571428571rem  +  23.2142857143vw , 25rem )) {
    ._article-variety .article__header-ibg {
      height: clamp( 18.5rem , 13.8571428571rem  +  23.2142857143vw , 25rem );
    }
  }
  @supports not (height: clamp( 18.5rem , 13.8571428571rem  +  23.2142857143vw , 25rem )) {
    ._article-variety .article__header-ibg {
      height: calc(18.5rem + 6.5 * (100vw - 20rem) / 28);
    }
  }
}
@media (max-width: 20em) {
  ._article-variety .article__header-ibg {
    height: 18.5rem;
  }
}
@media (max-height: 900px) and (min-width: 64em) and (max-width: 90em) {
  @supports (height: clamp( 40rem , 38.4615384615rem  +  2.4038461538vw , 40.625rem )) {
    ._article-variety .article__header-ibg {
      height: clamp( 40rem , 38.4615384615rem  +  2.4038461538vw , 40.625rem );
    }
  }
  @supports not (height: clamp( 40rem , 38.4615384615rem  +  2.4038461538vw , 40.625rem )) {
    ._article-variety .article__header-ibg {
      height: calc(40rem + 0.625 * (100vw - 64rem) / 26);
    }
  }
}
@media (max-height: 900px) and (min-width: 48.0625em) and (max-width: 63.9375em) {
  @supports (width: clamp( 26.75rem , -13.3651574803rem  +  83.4645669291vw , 40rem )) {
    ._article-variety .article__header-ibg {
      width: clamp( 26.75rem , -13.3651574803rem  +  83.4645669291vw , 40rem );
    }
  }
  @supports not (width: clamp( 26.75rem , -13.3651574803rem  +  83.4645669291vw , 40rem )) {
    ._article-variety .article__header-ibg {
      width: calc(26.75rem + 13.25 * (100vw - 48.0625rem) / 15.875);
    }
  }
}
@media (max-width: 768px) {
  ._article-variety .article__header-ibg {
    position: static;
    width: 100%;
  }
}
@media (max-width: 768px) {
  ._article-variety .article__header-author {
    top: unset;
    right: 12px;
    left: 0;
  }
}
@media (max-width: 768px) {
  ._article-variety .article__info {
    margin-top: 38px;
    column-gap: 12px;
  }
}
._article-variety .article__title {
  max-width: 408px;
  font-weight: 300;
  font-size: 48px;
  line-height: 1;
  font-family: Loos ExtraWide;
  color: #202020;
  letter-spacing: -0.03em;
}
@media (min-width: 48.0625em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.75rem , 0.3174366617rem  +  2.9806259314vw , 3rem )) {
    ._article-variety .article__title {
      font-size: clamp( 1.75rem , 0.3174366617rem  +  2.9806259314vw , 3rem );
    }
  }
  @supports not (font-size: clamp( 1.75rem , 0.3174366617rem  +  2.9806259314vw , 3rem )) {
    ._article-variety .article__title {
      font-size: calc(1.75rem + 1.25 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (min-width: 20em) and (max-width: 48em) {
  @supports (font-size: clamp( 2rem , 1.7321428571rem  +  1.3392857143vw , 2.375rem )) {
    ._article-variety .article__title {
      font-size: clamp( 2rem , 1.7321428571rem  +  1.3392857143vw , 2.375rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.7321428571rem  +  1.3392857143vw , 2.375rem )) {
    ._article-variety .article__title {
      font-size: calc(2rem + 0.375 * (100vw - 20rem) / 28);
    }
  }
}
@media (max-width: 20em) {
  ._article-variety .article__title {
    font-size: 2rem;
  }
}
@media (max-width: 768px) {
  ._article-variety .article__title {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 69px;
    overflow: visible;
  }
}
._article-variety .article__tag {
  color: #202020;
}
._article-variety .article__data {
  color: #202020;
}
._article-variety .article .article-authors {
  position: absolute;
  top: unset;
  bottom: -10px;
  left: -2px;
}
@media (max-width: 768px) {
  ._article-variety .article .article-authors {
    position: static;
  }
}
.article-authors {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
@media (min-width: 90em) {
  .article-authors {
    gap: 2.25rem;
  }
}
@media (min-width: 48.0625em) and (max-width: 90em) {
  @supports (gap: clamp( 0.9375rem , -0.5666915052rem  +  3.129657228vw , 2.25rem )) {
    .article-authors {
      gap: clamp( 0.9375rem , -0.5666915052rem  +  3.129657228vw , 2.25rem );
    }
  }
  @supports not (gap: clamp( 0.9375rem , -0.5666915052rem  +  3.129657228vw , 2.25rem )) {
    .article-authors {
      gap: calc(0.9375rem + 1.3125 * (100vw - 48.0625rem) / 41.9375);
    }
  }
}
@media (max-width: 48.0625em) {
  .article-authors {
    gap: 0.9375rem;
  }
}
@media (max-width: 768px) {
  .article-authors {
    gap: 24px;
  }
}
.article-authors__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: Loos Normal;
}
.article-authors__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: Loos ExtraWide;
}
.blockquote {
  display: flex;
  padding: 12px 0;
  column-gap: 55px;
  border-top: 2px solid #e35e59;
  border-bottom: 2px solid #e35e59;
}
@media (max-width: 768px) {
  .blockquote {
    flex-direction: column;
    row-gap: 15px;
  }
}
.blockquote__img-ibg {
  flex-shrink: 0;
  width: 65px;
  height: 65px;
}
.blockquote__content {
  display: flex;
  flex-direction: column;
}
.blockquote__author {
  margin-bottom: 19px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  color: #e35e59;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .blockquote__author {
      font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem );
    }
  }
  @supports not (font-size: clamp( 0.875rem , 0.8035714286rem  +  0.3571428571vw , 1.125rem )) {
    .blockquote__author {
      font-size: calc(0.875rem + 0.25 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .blockquote__author {
    font-size: 0.875rem;
  }
}
@media (max-width: 768px) {
  .blockquote__author {
    margin-bottom: 11px;
  }
}
.blockquote__author span {
  display: block;
}
.blockquote__author span:first-child {
  margin-bottom: -3px;
}
@media (max-width: 768px) {
  .blockquote__author span:first-child {
    margin-bottom: 0;
  }
}
.blockquote__description {
  margin-bottom: 72px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: Loos Normal;
  color: #e35e59;
}
@media (max-width: 768px) {
  .blockquote__description {
    margin-bottom: 37px;
  }
}
.blockquote__text {
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  font-family: Loos Normal;
  color: #e35e59;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .blockquote__text {
      font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem );
    }
  }
  @supports not (font-size: clamp( 1.125rem , 1.0714285714rem  +  0.2678571429vw , 1.3125rem )) {
    .blockquote__text {
      font-size: calc(1.125rem + 0.1875 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .blockquote__text {
    font-size: 1.125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .blockquote__text {
      line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem );
    }
  }
  @supports not (line-height: clamp( 1.5rem , 1.3571428571rem  +  0.7142857143vw , 2rem )) {
    .blockquote__text {
      line-height: calc(1.5rem + 0.5 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .blockquote__text {
    line-height: 1.5rem;
  }
}
@media (max-width: 768px) {
  .blockquote__text {
    margin-bottom: 21px;
  }
}
.note {
  padding: 12px 0;
  border-top: 2px solid #e35e59;
  border-bottom: 2px solid #e35e59;
}
.note__title {
  margin-bottom: 46px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  font-family: Loos ExtraWide;
  color: #e35e59;
}
@media (max-width: 768px) {
  .note__title {
    margin-bottom: 38px;
  }
}
.note__text {
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: Loos Normal;
  text-align: center;
  color: #e35e59;
}
@media (max-width: 768px) {
  .note__text {
    margin-bottom: 33px;
  }
}
.note__text span {
  display: block;
  margin-bottom: 12px;
  font-weight: 300;
  font-size: 96px;
  line-height: 96px;
  font-family: Loos ExtraWide;
  text-align: center;
  color: #e35e59;
  letter-spacing: -0.05em;
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem )) {
    .note__text span {
      font-size: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem )) {
    .note__text span {
      font-size: calc(2rem + 4 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .note__text span {
    font-size: 2rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem )) {
    .note__text span {
      line-height: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 0.8571428571rem  +  5.7142857143vw , 6rem )) {
    .note__text span {
      line-height: calc(2rem + 4 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .note__text span {
    line-height: 2rem;
  }
}
.share {
  margin-top: 33px;
  display: flex;
  max-width: 408px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 18px 22px 18px 40px;
  border-radius: 40px;
}
@media (max-width: 1451px) {
  .share {
    margin-left: 115px;
  }
}
@media (max-width: 768px) {
  .share {
    margin-left: 0;
  }
}
@media (max-width: 425px) {
  .share {
    max-width: 100%;
    flex-direction: column;
    padding: 39px 58px;
    margin-top: 12px;
  }
}
.share__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  margin-right: 42px;
  padding-top: 2px;
  color: #000000;
}
@media (max-width: 425px) {
  .share__title {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
    margin-right: 0;
  }
}
.share__list {
  display: flex;
  align-items: center;
}
.share__item {
  width: 36px;
  height: 36px;
}
.share__item:not(:last-child) {
  margin-right: 12px;
}
.share__link {
  display: block;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.share__icon {
  width: 36px;
  height: 36px;
  fill: #202020;
  opacity: 0.2;
  transition: all 0.2s linear;
}
.share__icon:hover {
  opacity: 1;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #fbf0e9;
}
@media (max-width: 425px) {
  .loader__header .logo-icon {
    top: 12px;
    left: 12px;
  }
}
.loader__main {
  display: flex;
  align-items: center;
  height: 100%;
}
.loader .infinite-line {
  margin-top: -125px;
  background-color: #e7fe59;
  transform: rotate(-10deg);
}
@media (max-width: 768px) {
  .loader .infinite-line {
    margin-top: -74px;
  }
}
.loader .infinite-line:after {
  right: -28px;
  width: 38px;
  height: 100%;
  background-color: #e7fe59;
}
.loader .infinite-line:before {
  left: -30px;
  width: 38px;
  height: 100%;
  background-color: #e7fe59;
}
.loader .infinite-line__block {
  margin-left: -235px;
  color: #202020;
  animation: loading 3s linear infinite;
}
@media (max-width: 768px) {
  .loader .infinite-line__block {
    animation: loading 2s linear infinite;
  }
}
@media (max-width: 425px) {
  .loader .infinite-line__block {
    margin-left: -155px;
    animation: loading 2s linear infinite;
  }
}
.loader .infinite-line__block p {
  margin-left: 145px;
}
@media (max-width: 768px) {
  .loader .infinite-line__block p {
    margin-left: 70px;
    font-size: 56px;
  }
}
@media (max-width: 425px) {
  .loader .infinite-line__block p {
    margin-left: 43px;
    font-size: 32px;
    letter-spacing: -0.03em;
  }
}
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-589px);
  }
}
@media (max-width: 768px) {
  @keyframes loading {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-330px);
    }
  }
}
@media (max-width: 425px) {
  @keyframes loading {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-196px);
    }
  }
}
.page-404 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: #fbf0e9;
}
@media (max-width: 425px) {
  .page-404__header .logo-icon {
    top: 12px;
    left: 12px;
  }
}
.page-404__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.page-404__link {
  font-weight: 300;
  font-size: 48px;
  line-height: 48px;
  font-family: "Loos ExtraWide";
  text-align: center;
  color: #202020;
  letter-spacing: -0.03em;
}
@media (min-width: 90em) {
  .page-404__link {
    font-size: 3rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .page-404__link {
      font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (font-size: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .page-404__link {
      font-size: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .page-404__link {
    font-size: 2rem;
  }
}
@media (min-width: 90em) {
  .page-404__link {
    line-height: 3rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .page-404__link {
      line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem );
    }
  }
  @supports not (line-height: clamp( 2rem , 1.7142857143rem  +  1.4285714286vw , 3rem )) {
    .page-404__link {
      line-height: calc(2rem + 1 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .page-404__link {
    line-height: 2rem;
  }
}
.page-404 .infinite-line {
  margin-top: 265px;
  margin-bottom: 237px;
  background-color: #e7fe59;
  transform: rotate(-10deg);
}
@media (min-width: 90em) {
  .page-404 .infinite-line {
    margin-bottom: 14.8125rem;
  }
}
@media (min-width: 20em) and (max-width: 90em) {
  @supports (margin-bottom: clamp( 8.0625rem , 6.1339285714rem  +  9.6428571429vw , 14.8125rem )) {
    .page-404 .infinite-line {
      margin-bottom: clamp( 8.0625rem , 6.1339285714rem  +  9.6428571429vw , 14.8125rem );
    }
  }
  @supports not (margin-bottom: clamp( 8.0625rem , 6.1339285714rem  +  9.6428571429vw , 14.8125rem )) {
    .page-404 .infinite-line {
      margin-bottom: calc(8.0625rem + 6.75 * (100vw - 20rem) / 70);
    }
  }
}
@media (max-width: 20em) {
  .page-404 .infinite-line {
    margin-bottom: 8.0625rem;
  }
}
@media (max-width: 768px) {
  .page-404 .infinite-line {
    margin-top: -74px;
  }
}
@media (max-width: 425px) {
  .page-404 .infinite-line {
    margin-top: 81px;
  }
}
.page-404 .infinite-line:after {
  right: -28px;
  width: 38px;
  height: 100%;
  background-color: #e7fe59;
}
.page-404 .infinite-line:before {
  left: -30px;
  width: 38px;
  height: 100%;
  background-color: #e7fe59;
}
.page-404 .infinite-line__block {
  margin-left: -500px;
  color: #202020;
  animation: loading 3s linear infinite;
}
@media (max-width: 768px) {
  .page-404 .infinite-line__block {
    animation: loading 2s linear infinite;
  }
}
@media (max-width: 425px) {
  .page-404 .infinite-line__block {
    margin-left: -157px;
    animation: loading 2s linear infinite;
  }
}
.page-404 .infinite-line__block p {
  margin-left: 145px;
}
@media (max-width: 768px) {
  .page-404 .infinite-line__block p {
    margin-left: 70px;
    font-size: 56px;
  }
}
@media (max-width: 425px) {
  .page-404 .infinite-line__block p {
    margin-left: 43px;
    font-size: 32px;
    letter-spacing: -0.03em;
  }
}
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-758px);
  }
}
@media (max-width: 768px) {
  @keyframes loading {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-428px);
    }
  }
}
@media (max-width: 425px) {
  @keyframes loading {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-253px);
    }
  }
}
.article-authors__title {
    font-weight: 400;
}
.article__text--main p {
    margin-bottom: 36px;
}
.article__text--main p a {
    text-decoration: underline;
    font-weight: 400;
}
.about__content p a {
    text-decoration: underline;
    font-weight: 500;
}
.article__text--main p:last-child {
    margin-bottom: 0;
}
.footer_up {
    opacity: 0;
}
.footer_up._active {
    opacity: 1;
}
.header__menu-btn {
    background-color: transparent;
}
@media (max-width: 425px) {
    .logo-icon {
        z-index: 999999;
    }
}
.social__link--ok {
    background: url('/img/icons/ok.svg') center/cover no-repeat;
}
.social__link--ok:hover {
    background: url('/img/icons/ok-red.svg') center/cover no-repeat;
}
.social__link--vk {
    background: url('/img/icons/vk.svg') center/cover no-repeat;
}
.social__link--vk:hover {
    background: url('/img/icons/vk-red.svg') center/cover no-repeat;
}
/* @media (max-width: 768px) {
    .news-list {
        width: unset;
    }
} */
/* @import "../../styles/fonts.css"; */
/* @import "../../styles/null.css"; */
/* @import "../../styles/base.css"; */
/* @import '../../styles/mixins.scss'; */
/* .app {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    color: #000000;
} */
/*
.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;

    &--branding {
        @media (max-width: 1200px) {
            margin-top: calc(
                -69px + (-258 - 69) * ((100vw - 375px) / (1200 - 375)) ;
            );
        }
    }
}
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;

    @media (max-width: 767.98px) {
        max-width: none;
        padding: 0;
    }
}

.container--page {
    @media (max-width: 767.98px) {
        padding: 0 16px;
    }
} */
.swiper-infinite {
  overflow: visible !important;

}
.swiper-infinite .swiper-wrapper{
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
}
.swiper-infinite {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    display: flex;
    width: 100%;
    height: 197px;
    margin-top: 106px;
    background-color: #322740;
    transform: rotate(3deg);
  }
@media (min-width: 20em) and (max-width: 90em) {
    @supports (margin-top: clamp( 4.75rem , 4.2142857143rem  +  2.6785714286vw , 6.625rem )) {
      .swiper-infinite {
        margin-top: clamp( 4.75rem , 4.2142857143rem  +  2.6785714286vw , 6.625rem );
      }
    }
    @supports not (margin-top: clamp( 4.75rem , 4.2142857143rem  +  2.6785714286vw , 6.625rem )) {
      .swiper-infinite {
        margin-top: calc(4.75rem + 1.875 * (100vw - 20rem) / 70);
      }
    }
  }
@media (max-width: 20em) {
    .swiper-infinite {
      margin-top: 4.75rem;
    }
  }
@media (min-width: 48em) and (max-width: 64em) {
    @supports (height: clamp( 7.5rem , -6.9375rem  +  30.078125vw , 12.3125rem )) {
      .swiper-infinite {
        height: clamp( 7.5rem , -6.9375rem  +  30.078125vw , 12.3125rem );
      }
    }
    @supports not (height: clamp( 7.5rem , -6.9375rem  +  30.078125vw , 12.3125rem )) {
      .swiper-infinite {
        height: calc(7.5rem + 4.8125 * (100vw - 48rem) / 16);
      }
    }
  }
@media (min-width: 20em) and (max-width: 48em) {
    @supports (height: clamp( 4.5rem , 4.1428571429rem  +  1.7857142857vw , 5rem )) {
      .swiper-infinite {
        height: clamp( 4.5rem , 4.1428571429rem  +  1.7857142857vw , 5rem );
      }
    }
    @supports not (height: clamp( 4.5rem , 4.1428571429rem  +  1.7857142857vw , 5rem )) {
      .swiper-infinite {
        height: calc(4.5rem + 0.5 * (100vw - 20rem) / 28);
      }
    }
  }
@media (max-width: 20em) {
    .swiper-infinite {
      height: 4.5rem;
    }
  }
.swiper-infinite:before {
    content: "";
    position: absolute;
    top: 0;
    left: -12px;
    z-index: -1;
    width: 14px;
    height: 94%;
    background-color: #322740;
    transform: rotate(3deg);
  }
.swiper-infinite:after {
    content: "";
    position: absolute;
    right: -12px;
    bottom: 0;
    z-index: -1;
    width: 14px;
    height: 94%;
    background-color: #322740;
    transform: rotate(3deg);
  }
.swiper-infinite._2 {
    z-index: -1;
    background-color: #e7fe59;
  }
@media (max-width: 768px) {
    .swiper-infinite._2 {
      margin-top: 43px;
    }
  }
.swiper-infinite._2:before {
    background-color: #e7fe59;
  }
.swiper-infinite._2:after {
    background-color: #e7fe59;
  }
/* .swiper-infinite .swiper-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Loos ExtraWide;
    color: #f4d4fc;
  } */
.swiper-infinite._2 .swiper-wrapper .swiper-slide {
    color: #202020;
  }
.swiper-infinite .swiper-wrapper .swiper-slide {
    /* width: 100%; */
    /* margin-left: 170px; */
    font-weight: 300;
    font-size: 96px;
    letter-spacing: -0.05em;
    white-space: nowrap;
    color: #f4d4fc;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
@media (max-width: 768px) {
    .swiper-infinite .swiper-wrapper .swiper-slide {
      font-size: 32px;
    }
  }

  