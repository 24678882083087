/* @import '../../styles/media.css';

.page {
    flex: 1 0 auto;
    flex-grow: 1;

    &_align_center {
        text-align: center;
    }

    &__container {
        max-width: 760px;
        margin: 25px auto 80px;

        @media (--viewport-mobile) {
            padding: 0 15px;
        }

        &_clear::after {
            content: '';
            clear: both;
            display: table;
        }
    }

    &__container-more {
        margin-top: 40px;
    }

    &__container h2 {
        text-align: left;
        padding-left: 10px;

        @media (--viewport-mobile) {
            text-align: center;
        }

        > .rect {
            @media (--viewport-mobile) {
                margin: 0 auto;
            }
        }
    }
}
.page {
    flex: 1 1 auto;

    &__articles {
        margin-bottom: 32px;

        @media (max-width: 1200px) {
            margin-bottom: calc(
                13px + (32 - 13) * ((100vw - 375px) / (1200 - 375))
            );
        }
    }

    &__subscription {
        /* margin-bottom: 32px; */
/* 
        @media (max-width: 991.98px) {
            display: none;
        }
    }
} */
